import classNames from "classnames";

import styles from "./index.module.scss";

export default function Container ({children, className = "", size, extraStyle}) {

    return (
        <div className={classNames(styles.container, "container", className, {
            [styles[`container-${size}`]]: size,
        })} style={extraStyle}>
            {children}
        </div>
    )
}
