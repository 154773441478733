import classNames from "classnames";
import { useFormik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";

import { useQuiz } from "features/assessment-quiz/Page/QuizContext";
import {createOrder, getOrderProcess} from "features/assessment-quiz/helpers";
import {abEchoClass, sanitizeTextForClassName} from "../../../../../../helpers/helpers";
import analytics from "helpers/analytics";

import { useDelayLink } from "../../../../../../helpers/hooks";

import Screen from "features/assessment-quiz/Quiz/components/Screen";
import TextBubble from "features/assessment-quiz/Quiz/components/TextBubble";
import Notice from "../../../../../../components/Notice";
import ContinueButton, { useContinueButton } from "features/assessment-quiz/Quiz/components/ContinueButton";
import { InfoIcon } from "../../../../../../components/Icon";
import FormTextarea from "components/FormTextarea";

import styles from './index.module.scss';

const hearingPrograms = {
    'Echo Pro': [
        { name: 'Automatik', description: 'In diesem Programm stellt sich Ihr Hörgerät automatisch auf die Umgebung ein.', selectedByDefault: true },
        { name: 'Gespräch in kleiner Gruppe' },
        { name: 'Gespräch in großer Gruppe' },
        { name: 'Ruhige Umgebung' },
        { name: 'Laute Umgebung' },
        { name: 'Auto / Zug / Flugzeug' },
        { name: 'Musik' },
        { name: 'Hallige Umgebung' },
        { name: 'Individuell', customText: true },
    ],
    'Echo One': [
        { name: 'Automatik', description: 'In diesem Programm stellt sich Ihr Hörgerät automatisch auf die Umgebung ein.', selectedByDefault: true },
        { name: 'Ruhige Umgebung' },
        { name: 'Laute Umgebung' },
        { name: 'TV' },
        { name: 'Musik' },
        { name: 'Sport im Freien' },
        { name: 'Individuell', customText: true },
    ],
}

function getHearingPrograms (selectedDevice) {
    const device = selectedDevice !== 'echo-pro' ? 'Echo One' : 'Echo Pro';

    if (!hearingPrograms[device]) {
        return [];
    }

    return hearingPrograms[device];
}

export default function HearingPrograms({ next: nextLink }) {
    const {answers, resetQuiz, getAdditionalAnswer} = useQuiz();
    const next = useDelayLink(nextLink);
    const orderProcess = getOrderProcess();
    const [data, setData] = useState({});

    const programs = getHearingPrograms(orderProcess?.mode || getAdditionalAnswer('geraetename'));
    const {loading, setLoading} = useContinueButton();
    const maxPrograms = window.assessmentQuizMeta.NumberOfListeningPrograms;

    const [selectedPrograms, setSelectedPrograms] = useState(
        programs
            .filter((program) => program.selectedByDefault)
            .map((program) => ({name: program.name}))
    );

    const form = useFormik({
        initialValues: programs.filter((program) => program.customText).map((program) => ({
            [program.name]: '',
        })),
        onSubmit: async (values) => {
            const programs = selectedPrograms.map((program) => {
                const customText = values[program.name];

                if (customText) {
                    program.text = customText;
                }

                return program;
            });

            setLoading(true);

            analytics.fireEvent("assessment2_ordered");
            const { data } = await createOrder({ ...answers, "hoerprogramme": programs });

            setData(data);

            if (data.success) {
                setLoading(false);
                next({ to: nextLink });
                sessionStorage.setItem("order_data", JSON.stringify(
                    {
                        "url": data.data.redirect,
                        "order_id": data.data.order_id,
                    },
                ));
                resetQuiz();
            } else {
                setLoading(false);
            }
        },
    })

    return (
        <Screen>

            {data.success === false &&
                <Notice
                    success={data.success}
                    message={data.data.error_message}
                    link={<Link to="/" onClick={() => resetQuiz()}>Fragebogen erneut ausfüllen ></Link>}
                />
            }

            <TextBubble>Letzter Schritt: Welche Hörprogramme sollen wir für Sie installieren?</TextBubble>

            <div className={styles.maxProgramInfo}>
                <InfoIcon fill='#0080E2'/>
                <p>Suchen Sie sich bis zu {maxPrograms} Hörprogramme aus. Alle Hörprogramme sind völlig kostenlos.</p>
            </div>

            <form onSubmit={form.handleSubmit}>
                {programs.map((program) => {
                    const selected = !!selectedPrograms.find((selectedProgram) => selectedProgram.name === program.name);
                    
                    return (
                        <div key={program.name} className={classNames(styles.program, abEchoClass('hearing-program', sanitizeTextForClassName(program.name)), {
                            [styles.selected]: selected,
                        })}>
                            <header className={styles.header} onClick={() => {
                                setSelectedPrograms((programs) => {
                                    if (selected) {
                                        return programs.filter((p) => p.name !== program.name);
                                    }

                                    if (selectedPrograms.length >= maxPrograms) {
                                        alert('Sie haben bereits alle Programmplätze belegt. Wählen Sie zunächst ein anderes Programm ab.');
                                        return programs;
                                    }
                                    
                                    return [...programs, {name: program.name}];
                                })
                            }}>
                                <div className={styles.checkbox}>
                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.05612 17.1064L3.68945 11.7397L4.71966 10.7095L9.05612 15.0459L18.2561 5.84595L19.2863 6.87616L9.05612 17.1064Z" fill="white"/>
                                    </svg>
                                </div>
                                <h3 className={styles.name}>{program.name} {program.selectedByDefault && '(vorausgewählt)'}</h3>
                            </header>
                            { program.description && <p className={styles.description}>{program.description}</p> }
                            { selected && program.customText && (
                                <div className={styles.content}>
                                    <FormTextarea
                                        name={program.name}
                                        placeholder="Beschreiben Sie hier Ihre Hörsituation..."
                                        form={form}
                                    />
                                </div>
                            )}
                        </div>
                    );
                }) }

                <p className={styles.infoText}>Sie haben <b>{selectedPrograms.length} von {maxPrograms}</b> Programme ausgewählt.</p>

                <ContinueButton loader={loading} type='submit' />
            </form>

        </Screen>
    );
}
