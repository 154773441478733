// Calc distance between two points.
export function calcDistance({lat: lat1, lng: lng1}, {lat: lat2, lng: lng2}) {

  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lng2 - lng1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in km.
}

// Converts numeric degrees to radians.
function toRad(value) {
  return value * Math.PI / 180;
}
