import {abEchoClass} from "../../helpers/helpers";

export default function ArrowRight ({size = null, fill = 'currentColor'}) {

    return (
        <svg className={abEchoClass('', 'arrow-right')} width={size} height={size} viewBox="0 0 12 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M3.49238 11.7001C3.32457 11.7001 3.14457 11.6401 3.01238 11.5079C2.74848 11.244 2.74848 10.824 3.01238 10.5601L7.56014 6.00008L3.01238 1.45232C2.74848 1.18842 2.74848 0.768421 3.01238 0.504517C3.27629 0.240145 3.69629 0.240145 3.96019 0.49233L8.98795 5.52009C9.12014 5.65228 9.18014 5.82009 9.18014 6.00009C9.18014 6.18009 9.10795 6.3479 8.98795 6.48009L3.96019 11.5079C3.828 11.64 3.66019 11.7 3.49238 11.7V11.7001Z" />
        </svg>
    )
}
