import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { createLead } from 'features/assessment-quiz/helpers';
import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';
import { useDelayLink } from 'helpers/hooks';

import FormInput from 'components/FormInput';
import ContinueButton, { useContinueButton } from 'features/assessment-quiz/Quiz/components/ContinueButton';
import Screen from 'features/assessment-quiz/Quiz/components/Screen';
import TextBubble from '../../components/TextBubble';

import styles from './index.module.scss';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Bitte geben Sie Ihre E-Mail-Adresse ein.').required('Bitte geben Sie Ihre E-Mail-Adresse ein.'),

});

export default function PlanAppointment ({serviceId, next: nextLink}) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();
    const {answers, setAnswer, resetQuiz} = useQuiz();

    const email = (answers['kontaktdaten'] && answers['kontaktdaten']['email']);
    const form = useFormik({
        initialValues: {
            email: email ?? '',
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            setAnswer('kontaktdaten', { ...answers.kontaktdaten, email: values.email }, () => setEnteredEmail(true));
        },
    });

    const [enteredEmail, setEnteredEmail] = useState(email);

    return (
        <Screen>

            {!enteredEmail ? (
                <>
                    <TextBubble>Bitte geben Sie Ihre E-Mail-Adresse ein, um einen Rückruftermin zu buchen.</TextBubble>

                    <form onSubmit={form.handleSubmit} className={styles.overlay}>
                        <FormInput
                            wrapperClassName={styles.inputWrapper}
                            name='email'
                            placeholder='E-Mail-Adresse'
                            form={form}
                        />

                        <ContinueButton type='submit' />
                    </form>
                </>
            ) : (
                <div className={styles.appointment}>
                    <object
                        type='text/html'
                        title='Termin buchen'
                        data={`https://book.timify.com?accountId=61e2d7cde8c9bf1109f707d0&fullscreen=1&hideCloseButton=1&serviceId=${serviceId}&locale=de-de`}
                        className={styles.iframe}
                    />

                    <p>Bitte buchen Sie einen Termin und klicken Sie danach auf <b>Weiter</b>.</p>

                    <ContinueButton loader={loading} onClick={async () => {
                        setLoading(true);
                        await createLead({...answers, hubspot_exitName: 'Termin gebucht'});
                        resetQuiz();
                        next({ to: nextLink }, () => setLoading(false));
                    }} />
                </div>
            )}

        </Screen>
    );
}
