import classNames from "classnames";
import styles from "./index.module.scss";
import {useLocation} from "react-router-dom";

export default function Screen ({children, className}) {
    const location = useLocation();

    return (
        <div className={classNames(styles.screen, className, `ab-echo-${location.pathname.replace(/\//g,"").toLowerCase()}`)}>
            {children}
        </div>
    );
}
