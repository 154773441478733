import React from 'react';
import FloatingLabel from "floating-label-react";

import classNames from 'classnames';

import styles from './index.module.scss';

const Textarea = ({className, floatingLabel = false, ...rest}) => (
  floatingLabel === true ?
    <FloatingLabel component="textarea" className={classNames(className, styles['floating-label'])} {...rest}/>
    :
    <textarea className={classNames(className, styles.textarea)} {...rest}/>
);

export default Textarea;
