import classNames from 'classnames';

import styles from './index.module.scss';
import {abEchoClass, sanitizeTextForClassName} from "../../../../../helpers/helpers";
import {useLocation} from "react-router-dom";

const variations = {
    rows: styles['variant-rows'],
    last_big: styles['variant-last_big'],
    first_big: styles['variant-first_big'],
    small: styles['variant-small'],
    highlight_v2: styles['variant-highlight_v2'],
}

export default function AnswersList ({ answers = [], variant = '', chosen, onChoose, returnType = 'value' }) {
    const location = useLocation();

    chosen = Array.isArray(chosen) ? chosen : [chosen];

    const variationClasses = variant && variant.split(' ').map((v) => variations[v] ?? v).join(' ');

    const hasHighlight = answers.some((item) => item.highlight),
        multipleRows = answers.length >= 3;

    return (
        <div className={classNames(styles.answersList, variationClasses, abEchoClass('answers-list', sanitizeTextForClassName(location.pathname)) , {
            [styles.hasHighlight]: hasHighlight,
            [styles.multipleRows]: multipleRows,
        })}>

            {answers.map((item) => {
                item.value = item.value ?? item.text;

                let { text, value, badge, highlight, image, recommendation } = item;
                const selected = chosen.includes(value);

                return (
                    <div className={classNames(styles.answersItemHolder, abEchoClass('answers-item', sanitizeTextForClassName(value)) )} key={value}>
                        <div className={classNames(styles.answersItem, 'answersItem', {
                            [styles.selected]: selected,
                            selected: selected,
                            [styles.highlight]: highlight,
                            [styles.image]: !!image,
                            [styles.embed]: window.assessmentQuizMeta.view === 'compact',
                        })} onClick={() => onChoose(returnType === 'value' ? item.value : item)}>
                            {badge && <div className={styles.badge}>{badge}</div>}

                            {image && <img src={image} alt={text} />}

                            <div className={styles.text}>{text}</div>

                        </div>
                        {recommendation && <div className={styles.recommendation}>{recommendation}</div>}
                    </div>
                );
            })}
        </div>
    );
}
