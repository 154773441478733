import classNames from 'classnames';

import LinkModal from 'components/LinkModal';
import { InfoIcon } from 'components/Icon';

import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';
import { getDiscounts, isEmbed, isSale } from "features/assessment-quiz/helpers";

import styles from './index.module.scss';

export default function ProductCard ({product, className = null, recommended = false, selected = false, onClick = null}) {
    const {getChosenAnswer, answers} = useQuiz();
    const ears = getChosenAnswer('welche-ohren') === 'für beide Ohren' ? 'both' : 'one';
    const qty = getChosenAnswer('welche-ohren') === 'für beide Ohren' ? 2 : 1;

    const handleLinkModalClick = (event) => {
        event.stopPropagation();
    };

    const discounts = getDiscounts(product.parentId, product.id, qty);

    return (
        <div className={classNames(styles.productCard, className, {
        [styles.recommended]: recommended,
        })}>
            <div className={classNames(styles.productInfo, {
                [styles.selected]: selected,
            })}
                 onClick={() => onClick(product)}>
                <img className={classNames(styles.image, {
                    [styles.isSale]: isSale(),
                    [styles.proAccum]: product.name === "Echo Pro Akku",
                    [styles.oneAccum]: product.name === "Echo One Akku",
                    [styles.embed]: isEmbed(),
                })} src={product.image} alt={product.name} />

                <div className={classNames(styles.productData, {
                    [styles.isSale]: isSale(),
                })}>
                    <h4 className={styles.name}>{product.name_html}</h4>

                    <p className={styles.ownContribution}>
                        Eigenanteil bei Kauf:&nbsp;
                        <span className={styles.price}>
                              {(isSale() && discounts !== 0) ?
                                  <>
                                      <s dangerouslySetInnerHTML={{ __html: product.ownContribution?.[getChosenAnswer("technologiestufe")][ears] ?? '' }} />&nbsp;
                                      <b dangerouslySetInnerHTML={{ __html: product.ownContribution?.discounts[getChosenAnswer("technologiestufe")]?.[ears] ?? '' }} />
                                  </> :
                                  <b dangerouslySetInnerHTML={{ __html: product.ownContribution?.[getChosenAnswer("technologiestufe")]?.[ears] ?? '' }} />
                              }
                            <LinkModal linkClassName={styles.infoIcon} linkText={<InfoIcon />}
                                       modalTitle="Eigenanteil bei Kauf" onClick={handleLinkModalClick}>
                                <p>Der Eigenanteil ist nur dann relevant, wenn Sie sich nach der Testphase aktiv für die Hörgeräte entscheiden. Die Testphase ist zu 100% kostenlos und unverbindlich.</p>
                                <p>Nulltarif bedeutet, dass Mitglieder der gesetzlichen Krankenkassen lediglich den gesetzlichen Eigenanteil (10€ pro Gerät) sowie eine Verwaltungspauschale (0€ - 50€) zahlen. Der genaue Betrag hängt von Ihrer Krankenkasse ab.</p>
                                <p>Voraussetzung ist immer ein Leistungsanspruch ggü. Ihrer Krankenkasse sowie eine ohrenärztliche Verordnung.</p>
                            </LinkModal>
                        </span>
                    </p>
                    <p>
                        ⌀ Eigenanteil beim Akustiker:&nbsp;
                        <span className={styles.price}>
                            {(isSale() && discounts !== 0) ?
                                <>
                                    <s dangerouslySetInnerHTML={{ __html: product.comparisonPrice[getChosenAnswer("technologiestufe")][ears] ?? '' }} />&nbsp;
                                    <b dangerouslySetInnerHTML={{ __html: product.comparisonPrice.discounts[getChosenAnswer("technologiestufe")][ears] ?? '' }} />
                                </> :
                                <b dangerouslySetInnerHTML={{ __html: product.comparisonPrice?.[getChosenAnswer("technologiestufe")]?.[ears] ?? '' }} />
                            }
                            <LinkModal
                                modalTitle="Vergleichpreis beim Akustiker"
                                linkClassName={styles.infoIcon}
                                linkText={<InfoIcon />}
                                onClick={handleLinkModalClick}
                            >
                                <p>
                                   Der Vergleichspreis ist der Durchschnittspreis aus einer Umfrage von Hörakustik-Filialen in Deutschland (n=38).
                                   Wir können die Hörgeräte günstiger anbieten, da wir uns Tausende Filialen deutschlandweit sparen.&nbsp;
                                    <a target="_blank" rel="noreferrer" href="/vergleichspreis">Mehr Infos zur Datenerhebung</a>
                                </p>
                            </LinkModal>
                        </span>
                    </p>
                </div>

                    {isSale() && product.saleCountDown}

            </div>

            {typeof recommended === "boolean" && recommended && product.recommendations && (
                <div className={styles.recommendation}>
                    <p className={styles.title}>Empfehlung auf Basis Ihrer Angaben</p>
                    <ul>
                        {product.recommendations.map((recommendation) => (
                            <li key={recommendation}>✓ {typeof recommendation === 'function' ? recommendation(answers) : recommendation}</li>
                        ))}
                    </ul>
                </div>
            )}

            {typeof recommended === 'object' && recommended && (
                <div className={classNames(styles.recommendation)}>
                    {recommended}
                </div>
            )}
        </div>
    );
}
