import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import {Route, Switch} from 'react-router';
import {useHistory, useLocation} from 'react-router-dom';
import {CSSTransition, SwitchTransition} from 'react-transition-group';

import {ReactComponent as ArrowIcon} from "assets/icons/arrow-up.svg";

import { useQuiz } from '../Page/QuizContext';

import Question from './components/Question';
import Modal from 'components/Modal';
import Button from 'components/Button';

import Header from '../partials/Header';

import Footer from '../partials/Footer';
import Container from './components/Container';
import Loading from './components/Loading';
import WelcomeScreen from "./components/WelcomeScreen";

import hearingAids from 'features/assessment-quiz/data/hearingAids';
import { formatPrice, getOrderProcess, isOrderProcess } from '../helpers';
import { untrailingslashit } from 'helpers/helpers';
import { getAbSetting } from "helpers/abTesting";
import {
    abLyftProductPageNewView,
	abLyftRemoveTrialQuestion,
	abLyftWelcomeScreen,
	abLyftNegativeHearingFlow,
	abLyftRemoveNextQuestionAnimation,
	abLyftConfig,
} from "helpers/abLyftConfig";

// Screens
import WhyNoHearingAids from './screens/WhyNoHearingAids';
import WhyDontStartNow from './screens/WhyDontStartNow';
import InfoPackage from './screens/InfoPackage';
import PlanAppointment from './screens/PlanAppointment';
import Survey from './screens/Survey';

import * as ExitScreens from './screens/ExitScreens';
import * as InfoScreens from './screens/InfoScreens';
import * as OrderProcess from './screens/OrderProcess';

import styles from './index.module.scss';

const isEmbed = window.assessmentQuizMeta.view === 'compact';

ReactModal.setAppElement(isEmbed ? null : isOrderProcess() ? '#hearing-aids-order-process' : '#assessment-quiz');

const appointmentServices = {
  generalConsultation: '6373f155591c3326c7f987dd',
}

const Quiz = ({
  embed = false,
}) => {

	const location = useLocation(),
		history = useHistory(),
		{ data, setData, setAdditionalAnswer, getAdditionalAnswer, setAnswer, getChosenAnswer, resetQuiz } = useQuiz();

  const [showPopup, setShowPopup] = useState(data.answersHistory.length > 0);
  const [welcomeScreenLoaded, setWelcomeScreenLoaded] = useState(false);
  const quizRef = useRef(null);
  const abExtraInfos = getAbSetting('extraInfos');

  //Reset data when we enter the Quiz
    useEffect(() => {
        if (location.pathname === '/' && isOrderProcess()) {
            resetQuiz()
        }
    }, []);

    useEffect(() => {
        let path = location.pathname.replace(/\//g, '');

        if (location.pathname === "/") {
            path = "alter";
        }

        if (path.startsWith("/")) {
            path = path.substring(1);
        }

        abLyftConfig({
            eventType: "activatePage",
            pageApiName: `questionnaire-question-${path}`,
        });

    }, [location.pathname]);

  useEffect(() => {

    if (!embed) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    } else {

      if (location.pathname === '/' || !quizRef.current) {
        return;
      }

      setTimeout(() => {
        const rect = quizRef.current.getBoundingClientRect();
        window.scrollTo({ top: rect.top + window.scrollY - 100, behavior: 'smooth' });
      }, 1000);
    }
  }, [location.pathname]);

  const orderProcess = getOrderProcess();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (params.get('krankenkasse')) {
            setAdditionalAnswer('krankenkasse-kategorie', params.get('krankenkasse'));
        }

        // check if user has hover capability
        const hasHover = !(matchMedia('(hover: none)').matches);
        if (!hasHover) {
            document.body.classList.add('no-hover');
        }

        if (orderProcess) {
            const additionalProductAttrData = JSON.parse(localStorage.getItem('additionalProductAttr'));

            if (orderProcess.hearingAid !== undefined) {
                const hearingAidID = orderProcess.hearingAid ?? getAdditionalAnswer('geraetewahl');

                setAdditionalAnswer('geraetewahl', hearingAidID);
                setAnswer('geraetewahl', hearingAidID);

                const additionalProductAttr = {};

                if (additionalProductAttrData) {
                    Object.entries(additionalProductAttrData).forEach(([key, value]) => {
                        if (value) {
                            additionalProductAttr[key] = value;
                        }
                    });

                    setAnswer('additionalProductAttr', additionalProductAttr);
                }

                setData((data) => {
                    delete data.answers.geraetewahl;
                    delete data.answers.additionalProductAttr;

                    Object.keys(additionalProductAttr).forEach(key => {
                        delete data.answers[key];
                    });

                    return {
                        ...data,
                        answers: {
                            ...data.answers,
                            geraetewahl: orderProcess.hearingAid,
                            additionalProductAttr: additionalProductAttr,  // Add the new object here
                        }
                    };
                });
            }
        }
    }, []);

  const todayDate = new Date();

	const animationDirection = history.action === 'POP' ? styles['slide-right'] : styles['slide-left'];

  const lastRoute = data.answersHistory.slice(-1);
  const currentStep = location.pathname.replace(/\//g, '');

    const changeContainerSize = () => {
        switch (currentStep) {
            case "geraetewahl":
                return abLyftProductPageNewView() ? "md" : "";
            default:
                return "";
        }
    };

    const changeContainerStyles = () => {
        switch (currentStep) {
            case "geraetewahl":
                return {
                    paddingRight: abLyftProductPageNewView() ? "0" : "20px",
                    paddingLeft: abLyftProductPageNewView() ? "0" : "20px",
                };
            default:
                return {};
        }
    };

    return (
    <div
      className={classNames(styles.quiz, 'font-primary', {
        [styles.embed]: embed,
      })}
    >
      <Header embed={embed} />

        {!welcomeScreenLoaded && location.pathname === "/" && abLyftWelcomeScreen() && (
            <WelcomeScreen
                setWelcomeScreenLoaded={setWelcomeScreenLoaded}
                welcomeScreenLoaded={welcomeScreenLoaded}
            />
        )}

      <Container className={styles.content} size={changeContainerSize()} extraStyle={changeContainerStyles()}>
        <div className={abLyftRemoveNextQuestionAnimation() ? '' : animationDirection} ref={quizRef}>

          {(!embed && !isOrderProcess()) && <ContinueQuizPopup show={showPopup} hidePopup={() => setShowPopup(false)} />}

          <SwitchTransition>
            <CSSTransition key={location.pathname} timeout={abLyftRemoveNextQuestionAnimation() ? 200 : 1000} classNames='slide'>
              <Switch location={location}>

                  <Route path="/" exact>
                      {(welcomeScreenLoaded || !abLyftWelcomeScreen()) && (
                          <Question
                              TextBubbleClass={(orderProcess?.mode === "echo" || !orderProcess) ? styles.textBubbleAgeStep : ""}
                              question={
                                  <>
                                      Hallo, ich bin Martina, Hörexpertin bei Echo.
                                      <span>Um Sie optimal für den Hörgeräte-Test zu qualifizieren, möchte ich Ihnen ein paar kurze Fragen stellen.</span>
                                      Wie alt sind Sie?
                                  </>
                              }
                              answers={[
                                  { text: "65 oder älter" },
                                  { text: "45 bis 64" },
                                  { text: "18 bis 44" },
                                  { text: "unter 18", next: "/exit-unter-18" },
                              ]}
                              chosen={getChosenAnswer("alter")}
                              onChoose={(answer) => setAnswer("alter", answer)}
                              next="/besitzen-sie-hg"
                          />
                      )}
                  </Route>

                <Route path='/exit-unter-18'>
                  <ExitScreens.Under18 />
                </Route>

                <Route path='/besitzen-sie-hg'>
                  <Question
                    variant='rows'
                    question='Besitzen Sie bereits ein Hörgerät?'
                    answers={[
                      { text: 'Ja', next: '/wie-alt-sind-aktuelle-hg' },
                      { text: 'Nein', next: isOrderProcess() ? '/haben-sie-hoertest' : getAbSetting('skipAlreadyOwnHearingAidStep') ? '/wie-sehr-eingeschraenkt' : '/fernseher-lauter' },
                      { text: 'Bisher nur testweise', next: isOrderProcess() ? '/haben-sie-hoertest' : '/fernseher-lauter' },
                    ]}
                    chosen={getChosenAnswer('besitzen-sie-hg')}
                    onChoose={(answer) => setAnswer('besitzen-sie-hg', answer)}
                  />
                </Route>

                <Route path='/wie-alt-sind-aktuelle-hg'>
                  <Question
                    variant='last_big'
                    question='Wie alt sind Ihre aktuellen Hörgeräte?'
                    answers={[
                      { text: '1-2 Jahre', next: isOrderProcess() ? '/haben-sie-hoertest' : '/unter-6jahre-kommt-privatkauf-in-frage' },
                      { text: '3-5 Jahre', next: isOrderProcess() ? '/haben-sie-hoertest' : '/unter-6jahre-kommt-privatkauf-in-frage' },
                      { text: '6 Jahre oder älter' },
                    ]}
                    chosen={getChosenAnswer('wie-alt-sind-aktuelle-hg')}
                    onChoose={(answer) => setAnswer('wie-alt-sind-aktuelle-hg', answer)}
                    next={isOrderProcess() ? '/haben-sie-hoertest' : getAbSetting('skipQuestionsAfterHearingAids') ? '/haben-sie-hoertest' :'/tragen-sie-hg'}
                  />
                </Route>

                <Route path='/unter-6jahre-kommt-privatkauf-in-frage'>
                  <Question
                    variant='rows'
                    question={
                      <>
                        Die Krankenkasse zahlt neue Hörgeräte in der Regel erst nach 6 Jahren.
                        <span style={{margin: "12px 0"}}>Sie können die Echo Hörgeräte auch ohne Beteiligung der Krankenkasse kaufen. Der Privatpreis beginnt bei {formatPrice(hearingAids[1].price * 2, 0)} für zwei Geräte.</span>
                        Kommt ein Privatkauf aktuell für Sie in Frage?
                      </>
                    }
                    answers={[
                      { text: 'Ja, ich suche nach neuen Hörgeräten' },
                      { text: 'Nein, ich möchte mich nur informieren', next: '/nicht-sofort-infopaket' },
                    ]}
                    chosen={getChosenAnswer('unter-6jahre-kommt-privatkauf-in-frage')}
                    onChoose={(answer) => setAnswer('unter-6jahre-kommt-privatkauf-in-frage', answer)}
                    next={isOrderProcess() ? '/haben-sie-hoertest' : getAbSetting('skipQuestionsAfterHearingAids') ? '/haben-sie-hoertest' :'/tragen-sie-hg'}
                  />
                </Route>

                <Route path='/fernseher-lauter'>
                  <Question
                    question='Müssen Sie den Fernseher lauter stellen, damit Sie alles verstehen?'
                    answers={[
                      { text: 'Oft' },
                      { text: 'Gelegentlich' },
                      { text: 'Selten' },
                      { text: 'Nie' },
                    ]}
                    chosen={getChosenAnswer('fernseher-lauter')}
                    onChoose={(answer) => setAnswer('fernseher-lauter', answer)}
                    next='/probleme-in-einzelgespraechen'
                  />
                </Route>

                <Route path='/probleme-in-einzelgespraechen'>
                  <Question
                    question='Haben Sie in Einzelgesprächen Probleme, Ihr Gegenüber zu verstehen?'
                    answers={[
                      { text: 'Oft' },
                      { text: 'Gelegentlich' },
                      { text: 'Selten' },
                      { text: 'Nie' },
                    ]}
                    chosen={getChosenAnswer('probleme-in-einzelgespraechen')}
                    onChoose={(answer) => setAnswer('probleme-in-einzelgespraechen', answer)}
                    next='/probleme-in-gruppengespraechen'
                  />
                </Route>

                <Route path='/probleme-in-gruppengespraechen'>
                  <Question
                    question='Fällt es Ihnen schwer, Gruppengesprächen zu folgen?'
                    answers={[
                      { text: 'Oft' },
                      { text: 'Gelegentlich' },
                      { text: 'Selten' },
                      { text: 'Nie' },
                    ]}
                    chosen={getChosenAnswer('probleme-in-gruppengespraechen')}
                    onChoose={(answer) => setAnswer('probleme-in-gruppengespraechen', answer)}
                    next='/sprechen-mitmenschen-undeutlich'
                  />
                </Route>

                <Route path='/sprechen-mitmenschen-undeutlich'>
                  <Question
                    question='Scheinen Ihre Mitmenschen undeutlich zu sprechen?'
                    answers={[
                      { text: 'Oft' },
                      { text: 'Gelegentlich' },
                      { text: 'Selten' },
                      { text: 'Nie' },
                    ]}
                    chosen={getChosenAnswer('sprechen-mitmenschen-undeutlich')}
                    onChoose={(answer) => setAnswer('sprechen-mitmenschen-undeutlich', answer)}
                    next='/probleme-mit-frauen-und-kindern'
                  />
                </Route>

                <Route path='/probleme-mit-frauen-und-kindern'>
                  <Question
                    question='Haben Sie insbesondere Probleme, Frauen oder Kinder gut zu verstehen?'
                    answers={[
                      { text: 'Oft' },
                      { text: 'Gelegentlich' },
                      { text: 'Selten' },
                      { text: 'Nie' },
                    ]}
                    chosen={getChosenAnswer('probleme-in-frauen-und-kindern')}
                    onChoose={(answer) => setAnswer('probleme-in-frauen-und-kindern', answer)}
                    next='/wie-sehr-eingeschraenkt'
                  />
                </Route>

                <Route path='/wie-sehr-eingeschraenkt'>
                  <Question
                    variant='rows'
                    question='Wie sehr fühlen Sie sich insgesamt durch den Hörverlust eingeschränkt?'
                    answers={[
                      { text: 'Sehr eingeschränkt' },
                      { text: 'Etwas eingeschränkt' },
                      { text: 'Gar nicht eingeschränkt' },
                    ]}
                    chosen={getChosenAnswer('wie-sehr-eingeschraenkt')}
                    onChoose={(answer) => setAnswer('wie-sehr-eingeschraenkt', answer)}
                    next='/haben-sie-hoertest'
                  />
                </Route>

                <Route path='/tragen-sie-hg'>
                  <Question
                    question='Tragen Sie Ihre aktuellen Hörgeräte regelmäßig?'
                    answers={[
                      { text: 'Ja, jeden Tag' },
                      { text: 'Nur zu besonderen Anlässen', next: '/warum-tragen-sie-hg-nicht' },
                      { text: 'Ich trage sie gar nicht', next: '/warum-tragen-sie-hg-nicht' },
                      { text: 'Ich habe sie verloren' },
                    ]}
                    chosen={getChosenAnswer('tragen-sie-hg')}
                    onChoose={(answer) => setAnswer('tragen-sie-hg', answer)}
                    next='/wie-viel-bezahlt'
                  />
                </Route>

                <Route path='/warum-tragen-sie-hg-nicht'>
                  <WhyNoHearingAids />
                </Route>

                <Route path='/info-hg-sollte-man-tragen'>
                  <InfoScreens.ShouldWearHearingAids next='/wie-viel-bezahlt' />
                </Route>

                <Route path='/wie-viel-bezahlt'>
                  <Question
                    variant='last_big'
                    question='Wie viel haben Sie selbst für Ihre Hörgeräte gezahlt (also zusätzlich zum Zuschuss der Krankenkasse)?'
                    answers={[
                      { text: <>0&nbsp;€</>, value: '0 €' },
                      { text: <>0 bis 1.000&nbsp;€</>, value: '0 bis 1.000 €' },
                      { text: <>1.000 bis 2.000&nbsp;€</>, value: '1.000 bis 2.000 €' },
                      { text: <>2.000 bis 3.000&nbsp;€</>, value: '2.000 bis 3.000 €' },
                      { text: <>mehr als 3.000&nbsp;€</>, value: 'mehr als 3.000 €' },
                    ]}
                    chosen={getChosenAnswer('wie-viel-bezahlt')}
                    onChoose={(answer) => setAnswer('wie-viel-bezahlt', answer)}
                    next='/haben-sie-hoertest'
                  />
                </Route>

                <Route path='/haben-sie-hoertest'>
                  <Question
                    variant='rows'
                    question='Haben Sie einen aktuellen Hörtest?'
                    answers={[
                        { text: "Ja, habe ich", value: "Ja" },
                        {
                            text: "Nein, noch nicht",
                            value: "Nein",
                            next: abLyftNegativeHearingFlow() ? "/hoertermin-abfrage" : isOrderProcess() ? "/haben-sie-smartphone" : "/warum-online-versorgung",
                        },
                    ]}
                    chosen={getChosenAnswer('haben-sie-hoertest')}
                    onChoose={(answer) => setAnswer('haben-sie-hoertest', answer)}
                    next={isOrderProcess() ? '/haben-sie-smartphone' : '/warum-online-versorgung'}
                  />
                </Route>

                <Route path="/hoertermin-abfrage">
                    <Question
                        variant="rows"
                        question="Haben Sie bereits einen Termin für einen Hörtest"
                        answers={[
                            { text: "Ja, habe ich", value: "Ja", next: "/termin-erfolgt" },
                            { text: "Nein, noch nicht", value: "Nein", next: "/ohne-termin" },
                        ]}
                        chosen={getChosenAnswer("hoertermin-abfrage")}
                        onChoose={(answer) => setAnswer("hoertermin-abfrage", answer)}
                    />
                </Route>

                <Route path="/termin-erfolgt">
                    <OrderProcess.Birthday
                        next="/warum-online-versorgung"
                        answerSlug="termin-erfolgt"
                        title="Wann haben Sie Ihren Hörtermin?"
                    />
                </Route>

                <Route path="/ohne-termin">
                    <InfoScreens.WithoutAnAppointment next="/warum-online-versorgung" />
                </Route>

                <Route path='/warum-online-versorgung'>
                  <Question
                    question='Was spricht Sie bei der Online-Versorgung durch Echo am meisten an?'
                    answers={[
                      { text: 'Die vielen positiven Bewertungen' },
                      { text: 'Bequeme Abwicklung von zu Hause aus' },
                      { text: 'Geld sparen' },
                      { text: 'Einfach mal ausprobieren' },
                    ]}
                    chosen={getChosenAnswer('warum-online-versorgung')}
                    onChoose={(answer) => setAnswer('warum-online-versorgung', answer)}
                    next={abExtraInfos.D ? '/haben-sie-smartphone' : abExtraInfos.C ? '/haben-sie-smartphone' :'/info-kundenzufriedenheit'}
                  />
                </Route>

                <Route path='/info-kundenzufriedenheit'>
                  <InfoScreens.CustomerSatisfaction next='/haben-sie-smartphone' />
                </Route>

                <Route path='/haben-sie-smartphone'>
                  <Question
                    variant='rows'
                    question='Haben Sie ein Smartphone, damit wir die Hörgeräte aus der Ferne einstellen können?'
                    answers={[
                      { text: 'Ja, ich habe eins' },
                      { text: 'Ja, ein Familienmitglied' },
                      { text: 'Nein', next: '/exit-kein-smartphone' },
                    ]}
                    chosen={getChosenAnswer('haben-sie-smartphone')}
                    onChoose={(answer) => setAnswer('haben-sie-smartphone', answer)}
                    next={abExtraInfos.D ? '/wichtig-bei-neuen-hg' : abExtraInfos.B ? '/wichtig-bei-neuen-hg' : '/info-fernanpassung'}
                  />
                </Route>

                <Route path='/exit-kein-smartphone'>
                  <ExitScreens.NoSmartphone next={abExtraInfos.D ? '/wichtig-bei-neuen-hg' : abExtraInfos.B ? '/wichtig-bei-neuen-hg' : '/info-fernanpassung'} />
                </Route>

                <Route path='/info-fernanpassung'>
                  <InfoScreens.RemoteAdjustment next={isOrderProcess() ? '/info-probehoeren-moeglich' : '/wichtig-bei-neuen-hg'} />
                </Route>

                <Route path='/wichtig-bei-neuen-hg'>
                  <Question
                    question='Was ist Ihnen am wichtigsten bei Ihren neuen Hörgeräten?'
                    answers={[
                      { text: 'Wiederaufladbare Akku-Technologie' },
                      { text: 'Unauffälliges Design' },
                      { text: 'Einstellen per Smartphone' },
                      { text: 'Kabellose Verbindung zu TV und Co.' },
                    ]}
                    chosen={getChosenAnswer('wichtig-bei-neuen-hg')}
                    onChoose={(answer) => setAnswer('wichtig-bei-neuen-hg', answer)}
                    next={abLyftRemoveTrialQuestion() ? "/info-probehoeren-moeglich" : "/wann-test-beginnen"}
                  />
                </Route>

                <Route path='/wann-test-beginnen'>
                  <Question
                    variant='rows'
                    question='Wann möchten Sie mit dem Test der Hörgeräte beginnen?'
                    answers={[
                      { text: todayDate.getDate() >= 26 ? 'direkt nächsten Monat (schnellstmöglich)' : 'diesen Monat (schnellstmöglich)', badge: 'Nur noch 14 freie Plätze' },
                      { text: 'In den nächsten Monaten', next: '/warum-nicht-sofort' },
                    ]}
                    chosen={getChosenAnswer('wann-test-beginnen')}
                    onChoose={(answer) => setAnswer('wann-test-beginnen', answer)}
                    next='/info-probehoeren-moeglich'
                  />
                </Route>

                <Route path='/warum-nicht-sofort'>
                  <WhyDontStartNow next='/nicht-sofort-infopaket' />
                </Route>

                <Route path='/nicht-sofort-welche-beratung'>
                  <Question
                    variant='rows'
                    question='Wie dürfen wir Ihre offenen Fragen beantworten?'
                    answers={[
                      { text: 'Kostenloses Beratungsgespräch', next: '/termin-allg-beratung' },
                      { text: 'Infos per E-Mail erhalten', next: '/frage-per-mail' },
                    ]}
                    chosen={getChosenAnswer('nicht-sofort-welche-beratung')}
                    onChoose={(answer) => setAnswer('nicht-sofort-welche-beratung', answer)}
                  />
                </Route>

                <Route path='/nicht-sofort-infopaket'>
                  <Question
                    variant='rows'
                    question='Möchten Sie ein kostenloses Infopaket per E-Mail erhalten?'
                    answers={[
                      { text: 'Ja, gerne' },
                      {
                        text: 'Nein, zurück zur Website',
                        next: () => {
                          resetQuiz();
                          return '@/';
                        }
                      },
                    ]}
                    chosen={getChosenAnswer('nicht-sofort-infopaket')}
                    onChoose={(answer) => setAnswer('nicht-sofort-infopaket', answer)}
                    next='/frage-per-mail'
                  />
                </Route>

                <Route path='/termin-allg-beratung'>
                  <PlanAppointment serviceId={appointmentServices.generalConsultation} next='@/infos-angefordert/?beratungstermin' />
                </Route>

                <Route path='/frage-per-mail'>
                  <InfoPackage next='@/infos-angefordert/?infopaket' />
                </Route>

                <Route path='/info-probehoeren-moeglich'>
                  <InfoScreens.Eligible next='/name' />
                </Route>

                <Route path='/name'>
                  <OrderProcess.Name next='/kontaktdaten' />
                </Route>

                <Route path='/kontaktdaten'>
                  <OrderProcess.ContactDetails next='/adresse' />
                </Route>

                <Route path='/adresse'>
                  <OrderProcess.Address next='/info-anmeldung-bestaetigt' />
                </Route>

                <Route path='/info-anmeldung-bestaetigt'>
                  <InfoScreens.SignUpConfirm next='/welche-ohren' />
                </Route>

                  <Route path='/welche-ohren'>
                      <Question
                          variant='first_big'
                          question='Brauchen Sie Hörgeräte für beide Ohren?'
                          answers={[
                              {text: 'für beide Ohren'},
                              {text: 'nur links'},
                              {text: 'nur rechts'},
                          ]}
                          chosen={getChosenAnswer('welche-ohren')}
                          onChoose={(answer) => setAnswer('welche-ohren', answer)}
                          next={(isOrderProcess() && getChosenAnswer('geraetewahl')) ? '/gesetzl-oder-privat' : abLyftProductPageNewView() ?  '/geraetewahl' : '/technologiestufe'} />
                  </Route>

                  <Route path='/technologiestufe'>
                      <OrderProcess.ChooseTechnologiestufeAttribute
                          next={isOrderProcess() && orderProcess.mode === 'beide' ? '/geraetewahl-beide' : '/geraetewahl'}/>
                  </Route>

                  {abLyftProductPageNewView() ?
                      <Route path="/geraetewahl">
                          <OrderProcess.ChooseDeviceNew next="/gesetzl-oder-privat" />
                      </Route> :
                      <Route path="/geraetewahl">
                          <OrderProcess.ChooseDevice next="/gesetzl-oder-privat" />
                      </Route>
                  }

                <Route path='/geraetewahl-beide'>
                  <OrderProcess.ChooseDeviceBoth next='/gesetzl-oder-privat' />
                </Route>

                <Route path='/gesetzl-oder-privat'>
                  <Question
                    variant='rows'
                    question='Sind Sie gesetzlich oder privat versichert?'
                    answers={[
                      { text: 'Gesetzlich versichert', value: 'GKV' },
                      { text: 'Privat versichert', value: 'PKV', next: '/geburtsdatum' },
                    ]}
                    chosen={getChosenAnswer('gesetzl-oder-privat')}
                    onChoose={(answer) => setAnswer('gesetzl-oder-privat', answer)}
                    next='/krankenkasse'
                  />
                </Route>

                <Route path='/krankenkasse'>
                  <OrderProcess.HealthInsurance next='/geburtsdatum' />
                </Route>

                <Route path='/besuch-im-fachgeschaeft'>
                  <OrderProcess.InsuranceSearch next='/geburtsdatum' />
                </Route>

                <Route path='/geburtsdatum'>
                  <OrderProcess.Birthday next='/hoerprogramme' title="Für die Hörgeräte-Anpassung brauchen wir Ihr Geburtsdatum." />
                </Route>

                <Route path='/hoerprogramme'>
                  <OrderProcess.HearingPrograms next='/pps' />
                </Route>

                <Route path='/pps'>
                  <Survey />
                </Route>

              </Switch>

            </CSSTransition>
          </SwitchTransition>

          { embed && history.location.pathname !== '/' && (
            <span className={styles.goBack} onClick={() => history.goBack()}>
              <ArrowIcon style={{ transform: 'rotate(-90deg)' }} /> Frage zurück
            </span>
          ) }

          <div
            className={classNames(styles.loading, {
              [styles.hide]: lastRoute[0]?.startsWith('info-'),
            })}
          >
            <Loading />
          </div>
        </div>
      </Container>

      { !embed && <Footer /> }
    </div>
	);
}

export default Quiz;

function ContinueQuizPopup ({ show, hidePopup }) {
  const {data, resetQuiz, setAnswer, addAnswerHistory} = useQuiz();

  const history = useHistory(),
    location = useLocation();

  let [lastAnswer] = data.answersHistory.slice(-1);

  function continueQuiz () {
    if (!lastAnswer) {
      return;
    }

    if (lastAnswer === 'alter') {
      lastAnswer = '';
    }

    history.push(`/${lastAnswer}`);

    hidePopup();
  }

    function restartQuiz() {
        resetQuiz();
        history.push("/");
        hidePopup();
    }

  if (untrailingslashit(location.pathname) === `/${lastAnswer}`) {
    return null;
  }

  return (
    <Modal
      isOpen={show}
      wrapperClassName={styles.continueQuizPopup}
      hideCloseButton
    >
      <h4>
        Sie waren schon mal hier.
        <br />
        Möchten Sie da weitermachen, wo Sie beim letzten Mal aufgehört haben?
      </h4>

      <Button style={{ marginTop: 24, marginBottom: 12 }} variant='primary' onClick={continueQuiz}>Weitermachen, wo ich aufgehört habe</Button>
      <Button variant='secondary' onClick={restartQuiz}>Neu starten</Button>
    </Modal>
  );
}
