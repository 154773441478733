import React from 'react';

import classNames from 'classnames';

import {abEchoClass, sanitizeTextForClassName} from "../../helpers/helpers";

import Input from "components/Input";

import styles from './index.module.scss';

const FormInput = ({name, form, wrapperClassName, ...rest}) => {
  return (
    <div className={classNames(styles['wrapper'], `${abEchoClass('input', sanitizeTextForClassName(name))}`, wrapperClassName)}>
      <Input
        name={name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={form.values[name]}
        {...rest}
      />
      {(form.touched[name] && form.errors[name]) &&
      <span>{form.errors[name]}</span>
      }
    </div>
  )
}

export default FormInput;
