import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from 'yup';

import { useQuiz } from "features/assessment-quiz/Page/QuizContext";

import { useDelayLink } from "helpers/hooks";
import Question from "../../components/Question";
import Screen from "../../components/Screen";
import FormTextarea from 'components/FormTextarea';
import ContinueButton, { useContinueButton } from '../../components/ContinueButton';

import styles from './index.module.scss';

const validationSchema = Yup.object().shape({
    message: Yup.string().required('Bitte geben Sie einen Grund an'),
});

export default function WhyDontStartNow ({ next: nextLink }) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();

    const { setAnswer, getChosenAnswer } = useQuiz();
    const form = useFormik({
        initialValues: {
            message: '',
        },
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            setLoading(true);
            setAnswer('warum-nicht-sofort', values.message);
            next({ to: nextLink }, () => setLoading(false));
        }
    });

    const [selectedOther, setSelectedOther] = useState(false);

    return (
        <Screen>

            <Question
                variant='rows'
                question='Was hält Sie davon ab, den Test sofort zu starten?'
                answers={[
                    { text: 'Ich habe noch Fragen', next: '/nicht-sofort-welche-beratung' },
                    { text: 'Ich muss erst zum HNO-Arzt für ein Hörtest / Rezept', next: nextLink },
                    { text: 'Sonstiges', highlight: selectedOther },
                ]}
                chosen={getChosenAnswer('warum-nicht-sofort')}
                onChoose={(answer) => {
                    setSelectedOther(answer === 'Sonstiges');
                    if (answer === 'Sonstiges') {
                        return;
                    }

                    setAnswer('warum-nicht-sofort', answer);
                }}
                />

            {selectedOther && (
                <form className={styles.other}
                    onSubmit={form.handleSubmit}
                >
                    <FormTextarea
                        name='message'
                        form={form}
                        floatingLabel
                        placeholder='Der Grund ist...' />

                    <ContinueButton loader={loading} type='submit' />
                </form>   
            )}

        </Screen>
    );
}
