import { useEffect, useState } from "react";

import { useQuiz } from "features/assessment-quiz/Page/QuizContext";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import TextBubble from "features/assessment-quiz/Quiz/components/TextBubble";
import { useContinueButton } from "features/assessment-quiz/Quiz/components/ContinueButton";
import ProductCardNew from "features/assessment-quiz/Quiz/components/ProductCardNew";
import Button from "../../../../../../components/Button";
import * as Modals from "features/assessment-quiz/Quiz/modals";

import config from "../../../../../../config";
import hearingAidsNew, { getRecommendedProduct, calcPrice } from "features/assessment-quiz/data/hearingAidsNew";
import { isOrderProcess } from "features/assessment-quiz/helpers";
import { useDelayLink } from "helpers/hooks";

import styles from "./index.module.scss";

const { echoProAccumVariationID, echoOneProductID } = config;

export default function ChooseDeviceNew({ next: nextLink }) {
	const { answers, setAnswer, getChosenAnswer } = useQuiz();
	const { loading, setLoading } = useContinueButton();

	const next = useDelayLink();
	const qty = getChosenAnswer("welche-ohren") === "für beide Ohren" ? 2 : 1;

	const initialProduct = isOrderProcess()
		? hearingAidsNew.find(({ id }) => id === echoProAccumVariationID)
		: getRecommendedProduct(answers);

	const [selectedProduct, setSelectedProduct] = useState(initialProduct);

	const [productConfigs, setProductConfigs] = useState(
		Object.fromEntries(
			hearingAidsNew.map(({ id }) => [id, { technologyLevel: "premium", energySource: "batterie" }]),
		),
	);

	useEffect(() => {
		hearingAidsNew.forEach(({ id, configurations }) => {
			const { technologyLevel, energySource } = productConfigs[id];
			calcPrice(configurations[technologyLevel][energySource], qty);
		});
	}, [productConfigs, selectedProduct, qty]);

	const handleProductClick = (product) => {
		const { technologyLevel, energySource } = productConfigs[product.id];
		setSelectedProduct(product);
		calcPrice(product.configurations[technologyLevel][energySource], qty);
	};

	const updateProductConfig = (productId, updates) => {
		setProductConfigs((prev) => {
			const updatedConfig = { ...prev[productId], ...updates };

			if (productId === echoOneProductID && updatedConfig.technologyLevel !== "highEnd") {
				updatedConfig.energySource = "batterie";
			}

			// calcPrice(selectedProduct.configurations[updatedConfig.technologyLevel][updatedConfig.energySource], qty);
			return { ...prev, [productId]: updatedConfig };
		});
	};

	return (
		<Screen className={styles.screen}>
			<TextBubble className={styles.textBubble}>Welche Hörgeräte möchten Sie testen?</TextBubble>

			<div className={styles.products}>
				{hearingAidsNew.map((product) => (
					<ProductCardNew
						key={product.id}
						product={product}
						recommended={selectedProduct.id === product.id}
						technologyLevel={productConfigs[product.id].technologyLevel}
						energySource={productConfigs[product.id].energySource}
						next={() => next({ to: nextLink }, () => setLoading(false))}
						onClick={() => handleProductClick(product)}
						onTechnologyLevelClick={(level) => updateProductConfig(product.id, { technologyLevel: level })}
						onEnergySourceClick={(source) => updateProductConfig(product.id, { energySource: source })}
						loading={loading}
						setLoading={setLoading}
					/>
				))}
			</div>

			<div className={styles.wrapper}>

				<p className={styles.info}>
					Der “Eigenanteil bei Kauf” wird erst fällig, wenn Sie sich nach der Testphase <b>aktiv</b> für die
					Hörgeräte entscheiden. Die Testphase ist 100% kostenlos und unverbindlich.
				</p>

				<Modals.HearingAids />

				{!isOrderProcess() && (
					<div className={styles.unsure}>
						<h3>Unsicher bei der Gerätewahl?</h3>

						<Button
							style={{ maxWidth: "420px", width: "100%" }}
							variant='secondary'
							onClick={() => {
								next({ to: '/geraetewahl-beide' });
								setAnswer("geraetewahl", "");
							}}
						>
							Echo One und Pro gleichzeitig testen
						</Button>
					</div>
				)}
			</div>
		</Screen>
	);
}
