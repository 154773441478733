import {useLocation} from "react-router-dom";

import EchoDoctorImage from 'assets/img/echo-doctor-image.png';

import styles from "./index.module.scss";
import classNames from 'classnames';
import {abEchoClass, sanitizeTextForClassName} from "../../../../../helpers/helpers";

export default function TextBubble ({children, className}) {
    const location = useLocation();

    return (
        <div className={classNames(styles.container, abEchoClass('text-bubble', sanitizeTextForClassName(location.pathname)))}>
            <div className={classNames( styles.textBubble, className )}>
                <img src={EchoDoctorImage} alt="Echo Doktor" />
                <p className={styles.text}>{children}</p>
            </div>
        </div>
    );
}
