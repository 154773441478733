// eslint-disable-next-line import/no-anonymous-default-export
export default {
  echoOneProductID: 62903,
  echoOneVariationID: 62906,
  echoOneHighEndVariationID: 66847,
  echoOneAkkuHighEndVariationID: 73420,
  echoProProductID: 62851,
  echoProBatteryVariationID: 62895,
  echoProBatteryHighEndVariationID: 66849,
  echoProAccumHighEndVariationID: 66848,
  echoProAccumVariationID: 62893,
  hearingAidsInsuranceProductID: 22264,

  GA4TrackingID: 'G-P2707N7ML9',

  googleApiKey: 'AIzaSyCbZR2bXxhOeP6jNyt_qNF6IdAWAPIZEoY',

  translations: {
    form: {
      required: 'Pflichtfeld',
      invalidEmail: 'Ungültige E-Mail',
      invalidZip: 'Die PLZ muss aus 5 Zahlen bestehen',
      invalidAddress: 'Bitte geben Sie Ihre Hausnummer an'
    }
  },

  unsupportedHealthInsurances: [],

  abSettingsDefaults: {
    skipQuestionsAfterHearingAids: false,
    showEchoProMitbestellen: false,
    backButtonExit: false,
    extraInfos: {
      B: false,
      C: false,
      D: false,
    },
    skipAlreadyOwnHearingAidStep: false,
  }
}
