import React from 'react';

import {Link} from "react-router-dom";

import {abEchoClass, sanitizeTextForClassName} from "../../helpers/helpers";

import classNames from 'classnames';

import styles from './index.module.scss';

const Button = (props) => {

  let {
    children,
    href = false,
    external = false,
    className,
    onClick,
    loader = false,
    variant,
    type = 'button',
    ...rest
  } = props;

  let btnClass = classNames('button', className, `${abEchoClass('button', sanitizeTextForClassName(children) )}`, styles['button'], {
    [styles['button--loading']]: loader,
    [styles['button--secondary']]: variant === 'secondary',
  });

  let opt = {
    ...rest,
    className: btnClass
  };

  if (onClick) {
    opt['onClick'] = onClick
  }

  if (href && !external) {
    return <Link to={href} {...opt}>{children}</Link>
  } else {

    let tag = 'button';

    if (href) {
      tag = 'a';
      opt['href'] = href;
    } else {
      opt['type'] = type;
    }

    return React.createElement(tag, opt, children);

  }

}

export default Button;
