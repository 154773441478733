import { useEffect, useState } from "react";

import AnswersList from "features/assessment-quiz/Quiz/components/AnswersList";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import TextBubble from "features/assessment-quiz/Quiz/components/TextBubble";
import { useDelayLink } from "helpers/hooks";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";
import FormInput from "components/FormInput";

import styles from './index.module.scss';
import { useFormik } from "formik";

const { healthInsurances, healthInsurancesByCategory } = window.assessmentQuizMeta;

export default function HealthInsurance ({ next: nextLink }) {
    const next = useDelayLink();
    const {getChosenAnswer, setAnswer, getAdditionalAnswer} = useQuiz();

    const [healthInsuranceCategory, _setHealthInsuranceCategory] = useState(null);

    function setHealthInsuranceCategory (category) {
        const preSelectedHealthInsurances = healthInsurancesByCategory[category];

        if (preSelectedHealthInsurances && preSelectedHealthInsurances.length === 1) {
            const store = preSelectedHealthInsurances[0];
            return redirect(store, category);
        }

        _setHealthInsuranceCategory(category);
    }

    function redirect (store, category = null) {
        const storeName = category ?? store.name;
        setAnswer('krankenkasse', storeName);

        if (store['require-store-visit']) {
            return next({ to: '/besuch-im-fachgeschaeft' });
        }

        return next({ to: nextLink });
    }

    useEffect(() => {
        if (getAdditionalAnswer('krankenkasse-kategorie')) {
            setHealthInsuranceCategory(getAdditionalAnswer('krankenkasse-kategorie'));
        }
    }, []);

    return (
        <Screen>

            <TextBubble>Bei welcher Krankenkasse sind Sie versichert?</TextBubble>

            {healthInsuranceCategory ? (
                    <>
                        <SearchHealthInsurance healthInsuranceCategory={healthInsuranceCategory} redirect={redirect} />
                    </>
                ) :
                <AnswersList
                    answers={
                        Object.keys(healthInsurancesByCategory)
                            .map((category) => ({ text: category }))
                    }
                    onChoose={setHealthInsuranceCategory}
                    chosen={getChosenAnswer("krankenkasse")}
                />}

        </Screen>
    );
}

function SearchHealthInsurance ({healthInsuranceCategory, redirect}) {
    const {getChosenAnswer} = useQuiz();

    const form = useFormik({
        initialValues: {
            'krankenkasse': '',
        },
    });

    const searchedHealthInsurances = (healthInsuranceCategory !== 'andere Krankenkasse' ? healthInsurancesByCategory[healthInsuranceCategory] : healthInsurances)
        .sort((a, b) => (a.name > b.name ? 1 : ((a.name < b.name) ? -1 : 0)))
        .filter((store) => {
            const value = form.values.krankenkasse.toLowerCase();
            return store.name.toLowerCase().includes(value) || store.synonyms.toLowerCase().includes(value);
        })
        .map((store) => ({ text: store.name, store }));

    return (
        <>
            {healthInsuranceCategory === 'andere Krankenkasse' && <FormInput
                className={styles.input}
                form={form}
                floatingLabel
                label='Krankenkasse'
                placeholder='Krankenkasse suchen...'
                name='krankenkasse'
            />}
                
            {healthInsurances.length > 0 ? (
                <AnswersList
                    variant='last_big'
                    answers={searchedHealthInsurances}
                    returnType='object'
                    onChoose={(healthInsurance) => {
                        const { store } = healthInsurance;
                        redirect(store);
                    }}
                    chosen={getChosenAnswer('krankenkasse')}
                />
            ) : <p>Keine Krankenkasse gefunden</p> }
        </>
    );
}
