import React from 'react';
import FloatingLabel from "floating-label-react";

import classNames from 'classnames';

import styles from './index.module.scss';

const Input = React.forwardRef(({className, type = "text", floatingLabel = false, ...rest}, ref) => (
  floatingLabel === true ?
    <FloatingLabel type={type} className={classNames(className, styles['floating-label'])} {...rest}/>
    :
    <input ref={ref} type={type} className={classNames(className, styles.input)} {...rest}/>
));

export default Input;
