
import styles from "./index.module.scss";

export default function Loading () {

    return (
        <div className={styles.loading}>

            <div>
                {Array.from({ length: 5 }).map((_, i) => <span key={i} style={{ animationDelay: `${-250 * i}ms` }} />)}
            </div>

            <p>Eingabe wird verarbeitet...</p>

        </div>
    );
}
