import { useFormik } from 'formik';
import * as Yup from 'yup';

import ContinueButton, { useContinueButton } from 'features/assessment-quiz/Quiz/components/ContinueButton';
import { useDelayLink } from 'helpers/hooks';
import TextBubble from 'features/assessment-quiz/Quiz/components/TextBubble';

import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';
import FormSelect from 'components/FormSelect';

import styles from './index.module.scss';

let days = ['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
	months = [
		{ label: 'Januar', value: '01' },
		{ label: 'Februar', value: '02' },
		{ label: 'März', value: '03' },
		{ label: 'April', value: '04' },
		{ label: 'Mai', value: '05' },
		{ label: 'Juni', value: '06' },
		{ label: 'Juli', value: '07' },
		{ label: 'August', value: '08' },
		{ label: 'September', value: '09' },
		{ label: 'Oktober', value: 10 },
		{ label: 'November', value: 11 },
		{ label: 'Dezember', value: 12 },
	],
	years = [];

const maxYear = new Date().getFullYear(),
	minYear = 1920;

for (let i = minYear; i <= maxYear; i++) {
	years.push(i);
}

const validationSchema = Yup.object().shape({
	day: Yup.string().defined('Bitte geben Sie einen Tag an').required('Bitte geben Sie einen Tag an'),
	month: Yup.string().defined('Bitte geben Sie einen Monat an').required('Bitte geben Sie einen Monat an'),
	year: Yup.string().defined('Bitte geben Sie einen Jahr an').required('Bitte geben Sie einen Jahr an'),
});

export default function Birthday ({ next: nextLink, answerSlug = 'geburtsdatum', title }) {
	const next = useDelayLink();
	const { setAnswer } = useQuiz(),
		{loading, setLoading} = useContinueButton();

	const form = useFormik({
		initialValues: {
			day: '',
			month: '',
			year: '',
		},
		validationSchema,
		validateOnChange: true,
		onSubmit: async (values) => {
			setLoading(true);
			setAnswer(answerSlug, `${values.year}-${values.month}-${values.day}`);
			next({ to: nextLink }, () => setLoading(false));
		},
	});

	return (
		<div>

			{title && <TextBubble>{title}</TextBubble>}

			<form onSubmit={form.handleSubmit}>
				<div className={styles.inputGroup}>
				<FormSelect
					name='day'
					options={[{value: '', label: 'Tag'}, ...days]}
					form={form}
				/>
				<FormSelect
					name='month'
					options={[{value: '', label: 'Monat'}, ...months]}
					form={form}
				/>
				<FormSelect
					name='year'
					options={[{value: '', label: 'Jahr'}, ...years]}
					form={form}
				/>
				</div>

				<ContinueButton loader={loading} type='submit' />
			</form>
		</div>
	);
}
