import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import Quiz from "../Quiz";
import QuizContext from './QuizContext';

const {routerBasename} = window.assessmentQuizMeta;

const Page = () => (
  <Router basename={routerBasename}>
    <QuizContext>
      <Quiz />
    </QuizContext>
  </Router>
);

export default Page;
