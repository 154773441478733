import {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

export function useDelayLink() {

  let timeout = null;
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);

  return ({to, delay = 500}, cb = null) => {
    // if trying to navigate to current page stop everything
    if (location?.pathname === to || to === undefined) return;

    timeout = setTimeout(() => {
      if (to.charAt(0) === '@') { // handle external link
        window.location = to.substring(1);
      } else {
        history.push(to);
      }
      
      if (cb) cb();
    }, delay);
  };

}
