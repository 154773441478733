import React, { useState, useEffect } from "react";

import Heading from "components/Quiz/Heading";

import styles from "./index.module.scss";

export default function WelcomeScreen({ setWelcomeScreenLoaded }) {
	const [displayPercentage, setDisplayPercentage] = useState(0);
	const [linePercentage, setLinePercentage] = useState(0);

	useEffect(() => {
		let intervalDuration = 38;
		const interval = setInterval(() => {
			if (linePercentage < 100) {
				setLinePercentage(prev => prev + 1);

				if (linePercentage < 90) {
					setDisplayPercentage(linePercentage + 1);
				} else if (linePercentage === 90) {
					clearInterval(interval);
					intervalDuration = 20;
					const fasterInterval = setInterval(() => {
						setLinePercentage(prev => {
							if (prev >= 100) {
								clearInterval(fasterInterval);
								setTimeout(() => {
									setWelcomeScreenLoaded(true);
								}, 270);
								return prev;
							}
							return prev + 1;
						});
					}, intervalDuration);
				}
			}
		}, intervalDuration);

		return () => clearInterval(interval);
	}, [linePercentage, setWelcomeScreenLoaded]);

	return (
		<div className={`${styles.loading} ${linePercentage >= 100 ? styles.slideOut : ""}`}>

			<a href="/" className={styles.logo}>
				<svg height="32" viewBox="0 0 195 64" xmlns="http://www.w3.org/2000/svg" fill="#000">
					<path
						d="M183.398 40L182.71 34.5806C181.935 28.4731 179.957 23.7419 176.258 19.4409C172.903 15.4839 167.398 12.4731 162.065 11.5269L156.731 10.6667L158.538 0L163.871 0.860215C171.699 2.15054 179.441 6.45161 184.516 12.3871C189.591 18.3226 192.516 24.9462 193.548 33.2043L194.237 38.6237L183.398 40Z"
						fill="#0080E2"></path>
					<path
						d="M168.688 25.9785C165.247 22.0215 160.516 20.043 154.667 20.043C148.731 20.043 144 22.0215 140.473 25.9785C136.946 29.9355 135.226 35.2688 135.226 42.0645C135.226 48.8602 136.946 54.1935 140.387 58.1505C143.828 62.0215 148.559 64 154.581 64C160.516 64 165.247 62.0215 168.688 58.1505C172.129 54.2796 173.849 48.9462 173.849 42.0645C173.936 35.1828 172.129 29.8495 168.688 25.9785ZM160.086 49.6344C158.796 51.4408 156.989 52.3871 154.667 52.3871C152.344 52.3871 150.538 51.4408 149.161 49.6344C147.785 47.8279 147.097 45.2473 147.097 41.9785C147.097 38.7097 147.785 36.129 149.161 34.3226C150.538 32.4301 152.344 31.5699 154.667 31.5699C156.989 31.5699 158.796 32.5161 160.086 34.3226C161.376 36.215 161.978 38.7097 161.978 41.9785C162.065 45.2473 161.376 47.8279 160.086 49.6344Z"></path>
					<path
						d="M101.247 62.8817V40.2581C101.247 34.4086 103.742 31.4839 108.645 31.4839C111.054 31.4839 112.774 32.172 113.72 33.6344C114.753 35.0968 115.269 37.3333 115.269 40.4301V62.8817H127.14V35.6989C127.14 30.1935 125.677 26.2365 122.839 23.7419C119.914 21.2473 116.559 20.043 112.688 20.043C107.785 20.043 104 21.6774 101.333 24.8602V3.78494L89.4624 9.29031V62.8817H101.247Z"></path>
					<path
						d="M62.9677 63.8279C72.4301 63.8279 78.6237 59.0968 81.3763 49.6344L70.0215 46.3656C68.7312 50.4086 66.4086 52.3871 62.9677 52.3871C60.8172 52.3871 59.0108 51.4408 57.5484 49.4624C56.172 47.4839 55.3979 44.9892 55.3979 41.8925C55.3979 38.7957 56.086 36.215 57.5484 34.3226C59.0108 32.3441 60.8172 31.3978 63.0538 31.3978C66.2366 31.3978 68.5591 33.2043 69.9355 36.8172L81.2043 33.1183C78.4516 24.4301 72.3441 20.043 62.8817 20.043C56.9462 20.043 52.2151 22.0215 48.6882 25.9785C45.0753 29.9355 43.3548 35.2688 43.3548 41.9785C43.3548 48.7742 45.1613 54.1075 48.6882 58.0645C52.2151 61.9355 57.0323 63.8279 62.9677 63.8279Z"></path>
					<path
						d="M42.8387 62.8817L33.2903 51.0108H12.4731V39.2258H28.5591V27.4409H12.4731V17.3763H32.8602L41.2043 5.5914H0V62.8817H42.8387Z"></path>
				</svg>
			</a>

			<Heading>
				Finden Sie jetzt heraus, ob Sie die Echo Hörgeräte kostenlos probetragen können.
			</Heading>

			<div className={styles.progressBar}>
				<div className={styles.progress} style={{ width: `${linePercentage}%` }}></div>

				<div className={styles.percentage} style={{ color: displayPercentage >= 50 ? "#FFF" : "#000" }}>
					{displayPercentage}%
				</div>
			</div>
		</div>
	);
}
