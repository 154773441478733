const abLyft = window.ablyft;

export const abLyftExperiments = {
	abLyftRemoveTrialQuestion: {
		experimentId: 87521772,
		activeVariationId: 32364839,
	},

	abLyftWelcomeScreen: {
		experimentId: 14159292,
		activeVariationId: 12462941,
	},

	// NOTE: PB-997 (AB-Test implementation flag)
	abLyftNegativeHearingFlow: {
		experimentId: 30285123,
		activeVariationId: 75331896,
	},

	//NOTE: PB-1070 (A/B Test)
	abLyftRemoveNextQuestionAnimation: {
		experimentId: 37269773,
		activeVariationId: 69727287,
	},

	// NOTE: PB-1052 (A/B Test implementation flag)
	abLyftProductPageNewView: {
		experimentId: 48086511,
		activeVariationId: 48137935,
	},
};

const isVariationActive = (experimentId, variationId) => {
	if (!abLyft || !("get" in abLyft)) return false;

	const items = abLyft.get("activeExperimentIds");
	if (!items) return false;

	const variation = items[experimentId];
	if (!variation) return false;

	return variation === variationId;
};

export function abLyftConfig(config) {
	const { experimentId, variationId, windowValue, pageApiName, eventType } = config;

	if (window.ablyft) {
		const eventParams = {};

		if (eventType) {
			eventParams.eventType = eventType;
		}

		if (experimentId) {
			eventParams.experimentId = experimentId;
		}

		if (variationId) {
			eventParams.variationId = variationId;
		}

		if (pageApiName) {
			eventParams.pageApiName = pageApiName;
		}

		window.ablyft.push(eventParams);

		if (windowValue) {
			window[windowValue] = true;
		}
	}
}

export const abLyftRemoveTrialQuestion = () =>
	isVariationActive(
		abLyftExperiments.abLyftRemoveTrialQuestion.experimentId,
		abLyftExperiments.abLyftRemoveTrialQuestion.activeVariationId
	);

export const abLyftWelcomeScreen = () =>
	isVariationActive(
		abLyftExperiments.abLyftWelcomeScreen.experimentId,
		abLyftExperiments.abLyftWelcomeScreen.activeVariationId,
	);

export const abLyftNegativeHearingFlow = () =>
	isVariationActive(
		abLyftExperiments.abLyftNegativeHearingFlow.experimentId,
		abLyftExperiments.abLyftNegativeHearingFlow.activeVariationId
	);

export const abLyftRemoveNextQuestionAnimation = () =>
	isVariationActive(
		abLyftExperiments.abLyftRemoveNextQuestionAnimation.experimentId,
		abLyftExperiments.abLyftRemoveNextQuestionAnimation.activeVariationId
	);

export const abLyftProductPageNewView = () =>
	isVariationActive(
		abLyftExperiments.abLyftProductPageNewView.experimentId,
		abLyftExperiments.abLyftProductPageNewView.activeVariationId,
	);