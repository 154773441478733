import { useQuiz } from "features/assessment-quiz/Page/QuizContext";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import TextBubble from "features/assessment-quiz/Quiz/components/TextBubble";
import ContinueButton, { useContinueButton } from "features/assessment-quiz/Quiz/components/ContinueButton";

import EchoOneImage from 'assets/img/assessment-quiz/echo-one.png';
import EchoProImage from 'assets/img/assessment-quiz/echo-pro.png';

import { useDelayLink } from "helpers/hooks";

import styles from './index.module.scss';
import { fireQuizAnalyticsEvent } from "features/assessment-quiz/helpers";

export default function ShouldWearHearingAids ({ next: nextLink }) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();

    return (
        <Screen>

            <TextBubble>
                Hörgeräte sollten <u>unbedingt</u> regelmäßig getragen werden.
            </TextBubble>

            <p>Der Grund ist die <b>Hörentwöhnung</b>: Tragen Sie keine Hörgeräte trotz Hörverlust, verschlechtert sich Ihr Hören immer mehr. </p>

            <RecommendedProduct />

            <ContinueButton loader={loading} onClick={() => {
                setLoading(true);
                fireQuizAnalyticsEvent('info-hg-sollte-man-tragen', '');
                next({ to: nextLink }, () => setLoading(false));
            }} />

        </Screen>
    );
}

function RecommendedProduct () {
    const { getChosenAnswer } = useQuiz();
    const answer = getChosenAnswer('warum-tragen-sie-hg-nicht');

    return (
        <div className={styles.recommendedProduct}>
            <img className={styles.image} src={answer === 'Sie sind mir peinlich' ? EchoOneImage : EchoProImage} alt='Echo Hearing Aid' />
            {getRecommendedProductText(answer)}
        </div>
    )
}

function getRecommendedProductText (answer) {
    if (answer === 'Sie sind mir peinlich') {
        return (
            <>
                <p>Wenn Ihnen Ihre aktuellen Hörgeräte peinlich sind, empfehlen wir Ihnen die <b>Echo One</b>.</p>
                <p>Sie sitzen dezent im Ohr und sind auch bei kurzen Haaren praktisch unsichtbar. Mitmenschen bemerken nicht, dass Sie Hörgeräte tragen.</p>
            </>
        );
    }
    
    if (answer === 'Sie sind unbequem') {
        return (
            <>
                <p>Wenn Ihre aktuellen Hörgeräte unbequem sind, empfehlen wir Ihnen die <b>Echo Pro</b>.</p>
                <p>Ultraweiche Silikon-Aufsätze sitzen perfekt im Ohr und erzeugen kein Fremdkörper-Gefühl.</p>
            </>
        );
    }

    if (answer === 'Sie helfen nicht wie erhofft') {
        return (
            <>
                <p>Wenn Sie mit Ihren aktuellen Gerät nicht gut genug hören, empfehlen wir Ihnen die <b>Echo Pro</b>.</p>
                <p>Sie haben einen der leistungsstärksten Sprachprozessoren der Welt. So verstehen Sie Sprache auch dann, wenn die Umgebung sehr laut ist.</p>
            </>
        );
    }
    
    return (
        <>
            <p>Wenn Sie mit Ihren aktuellen Gerät nicht zufrieden sind, empfehlen wir Ihnen die <b>Echo Pro</b>.</p>
            <p>93% aller Echo-Pro-Träger tragen die Hörgeräte regelmäßig.</p>
            <small>Quelle: interne Kundenumfrage 11/2022 (n=246)</small>
        </>
    );
}
