import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

const SaleCountdownBox = ({ size }) => {
	const { end, saleName } = window.echoSale;

	const [timeLeft, setTimeLeft] = useState({});

	useEffect(() => {
		const createBerlinTime = () => {
			return new Date(new Date().toLocaleString("en-US", { timeZone: "Europe/Berlin" }));
		};

		const calculateTimeLeft = () => {
			const now = createBerlinTime().getTime();
			const endDate = new Date(end * 1000).getTime(); // Convert end from seconds to milliseconds
			const diff = (endDate - now) / 1000; // Difference in seconds

			let days = 0;
			let hours = 0;
			let minutes = 0;
			let seconds = 0;

			if (diff > 0) {
				// Calculating the difference in days, hours, minutes, and seconds
				days = Math.floor(diff / (3600 * 24));
				hours = Math.floor((diff % (3600 * 24)) / 3600);
				minutes = Math.floor((diff % 3600) / 60);
				seconds = Math.floor(diff % 60);

				// Formatting the values to ensure two digits
				days = days < 10 ? "0" + days : days;
				hours = hours < 10 ? "0" + hours : hours;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;
			}

			setTimeLeft({
				days,
				hours,
				minutes,
				seconds,
			});
		};

		calculateTimeLeft();

		const interval = setInterval(calculateTimeLeft, 1000);

		return () => clearInterval(interval);
	}, [end]);

	return (
		<div className={classNames(styles.countdownContainer, {
			[styles.countdownContainerSm]: size === "sm",
		})}>
			<h4>{saleName} endet in:</h4>
			<ul className={classNames(styles.timeBox,{
				[styles.timeBoxSm]: size === "sm",
			})}>
				<li><span>{timeLeft.days}</span> <small>Tagen</small></li>
				<li><span>{timeLeft.hours}</span> <small>Std</small></li>
				<li><span>{timeLeft.minutes}</span> <small>Min</small></li>
				<li><span>{timeLeft.seconds}</span> <small>Sek</small></li>
			</ul>
		</div>
	);
};

export default SaleCountdownBox;
