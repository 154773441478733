import {useState} from 'react';

import {useQuiz} from 'features/assessment-quiz/Page/QuizContext';

import Screen from 'features/assessment-quiz/Quiz/components/Screen';
import TextBubble from 'features/assessment-quiz/Quiz/components/TextBubble';

import {Attributes} from 'features/assessment-quiz/data/hearingAids';
import {isOrderProcess} from 'features/assessment-quiz/helpers';

import {useDelayLink} from 'helpers/hooks';

import styles from './index.module.scss';
import AttributeCard from "../../../components/AttributeCard";

export default function ChooseTechnologiestufeAttribute({next: nextLink}) {

    const next = useDelayLink();

    const {setAnswer, getChosenAnswer} = useQuiz();
    const recommendedProduct = 'high-end';

    // Order process data
    const [selectedAttribute, setSelectedAttribute] = useState(recommendedProduct);

    const handleAttributeClick = (attribute) => {
        setAnswer('technologiestufe', attribute.type);
        setSelectedAttribute(attribute.type);

        next({to: nextLink});
    }

    return (
        <Screen className={styles.screen}>

            <TextBubble>Welche Technikstufe möchten Sie testen?</TextBubble>

            {Attributes.technologiestufe.map((attribute) => {
                const isSelected = getChosenAnswer('technologiestufe') ? getChosenAnswer('technologiestufe') === attribute.type : selectedAttribute === attribute.type,
                    isRecommended = recommendedProduct === attribute.type,
                    isDisabled = attribute.disabled;

                    return (
                        <AttributeCard
                            onClick={() => handleAttributeClick(attribute)}
                            attribute={attribute}
                            isDisabled={isDisabled}
                            key={attribute.name}
                            recommended={!isOrderProcess() ? isRecommended : (isRecommended && (
                                <span><b>Empfohlen:</b> Sie sparen rund 200€ Batteriekosten pro Gerät über 6 Jahre (mehr als der Akku-Aufpreis).</span>
                            ))}
                            selected={isSelected}
                        />
                    );
                }
            )}

            <p className={styles.info}>Der “Eigenanteil bei Kauf” wird erst fällig, wenn Sie sich nach der
                Testphase <b>aktiv</b> für die Hörgeräte entscheiden. Die Testphase ist 100% kostenlos und
                unverbindlich.</p>

        </Screen>
    )
}
