import classNames from 'classnames';

import LinkModal from 'components/LinkModal';
import { InfoIcon } from 'components/Icon';

import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';

import styles from './index.module.scss';

export default function AttributeCard ({attribute, className = null, recommended = false, selected = false, onClick = null, isDisabled = false}) {
    const {getChosenAnswer, answers} = useQuiz();
    const ears = getChosenAnswer('welche-ohren') === 'für beide Ohren' ? 'both' : 'one';

    const handleLinkModalClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div className={classNames(styles.productCard, className, {
        [styles.productCardDisabled]: isDisabled,
        [styles.recommended]: recommended,
        })}>
            <div className={classNames(styles.productInfo, styles.selected, {
                [styles.disabled]: isDisabled,
            })} onClick={() => onClick(attribute)}>
                {attribute.image && <img className={styles.image} src={attribute.image} alt={attribute.name} />}

                <div className={styles.productData}>
                    <h4 className={styles.name}>{attribute.name_html} <i>{attribute.starElements && attribute.starElements}</i></h4>

                    {attribute.staticText && attribute.staticText}

                    {attribute.ownContribution &&
                        <p className={styles.ownContribution}>
                        Eigenanteil bei Echo:&nbsp;
                        <span className={styles.price}>
                            <b dangerouslySetInnerHTML={{ __html: attribute.ownContribution[ears] }} />
                            <LinkModal linkClassName={styles.infoIcon} linkText={<InfoIcon />} modalTitle='Eigenanteil bei Echo:' onClick={handleLinkModalClick}>
                                <p>Der Eigenanteil ist nur dann relevant, wenn Sie sich nach der Testphase aktiv für die Hörgeräte entscheiden. Die Testphase ist zu 100% kostenlos und unverbindlich.</p>
                                <p>Nulltarif bedeutet, dass Mitglieder der gesetzlichen Krankenkassen lediglich den gesetzlichen Eigenanteil (10€ pro Gerät) sowie eine Verwaltungspauschale (0€ - 50€) zahlen. Der genaue Betrag hängt von Ihrer Krankenkasse ab.</p>
                                <p>Voraussetzung ist immer ein Leistungsanspruch ggü. Ihrer Krankenkasse sowie eine ohrenärztliche Verordnung.</p>
                            </LinkModal>
                        </span>
                    </p>}

                    {attribute.referencePrices &&
                        <p className={styles.referencePrices}>
                            ⌀ Eigenanteil beim <br/> Akustiker:&nbsp;
                            <span className={styles.price}>
                        <b dangerouslySetInnerHTML={{__html: attribute.referencePrices[attribute.type][ears]}}/>
                            <LinkModal linkClassName={styles.infoIcon} linkText={<InfoIcon/>} modalTitle='Eigenanteil beim Akustiker:' onClick={handleLinkModalClick}>
                                <p>
                                    Den Vergleichspreis haben wir aus einer Preisabfrage bei verschiedenen Akustikern in ganz Deutschland ermittelt. Der Vergleichspreis
                                    ist hierbei der Eigenanteil des Patienten - das heißt der Betrag, den Sie nach Abzug der Kassenleistung selbst zahlen müssten.&nbsp;
                                    <a target='_blank' rel='noreferrer' href='/vergleichspreis'>Mehr Infos zur Datenerhebung</a>
                                </p>
                            </LinkModal>
                        </span>
                        </p>}

                </div>
            </div>

            {typeof recommended === 'boolean' && recommended && attribute.recommendations && (
                <div className={styles.recommendation}>
                    <p className={styles.title}>{attribute.recommendationsTitle}</p>
                    <ul>
                        {attribute.recommendations.map((recommendation, index) => (
                            <li key={index}>✓ {typeof recommendation === 'function' ? recommendation(answers) : recommendation}</li>
                        ))}
                    </ul>
                </div>
            )}

            {typeof recommended === 'object' && recommended && (
                <div className={classNames(styles.recommendation)}>
                    {recommended}
                </div>
            )}
        </div>
    );
}
