import Button from "components/Button";
import ArrowRight from "components/Icon/ArrowRight";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import { useDelayLink } from "helpers/hooks";

export default function ExitNoSmartphone ({ next: nextLink }) {
    const next = useDelayLink(),
        {resetQuiz} = useQuiz();

    return (
        <Screen>

            <p>Leider brauchen Sie ein Smartphone, damit wir Sie aus der Ferne betreuen können.</p>

            <Button
                style={{ width: '100%', marginBottom: 24 }}
                onClick={() => {
                    resetQuiz();
                    next({ to: '@/' });
                }}
            >
                Zurück zur Website <ArrowRight size={12} />
            </Button>

            <p><b>Wichtig:</b> Sie müssen nicht zwangsläufig ein eigenes Smartphone haben. Das Smartphone wird nur für die Fernanpassungen benötigt. Einige unserer Kunden holen sich für den Fall ein Familienmitglied mit Smartphone dazu.</p>

            <Button style={{ width: '100%', marginBottom: 24 }} variant='secondary' onClick={() => next({ to: nextLink })}>Das kriege ich auch hin</Button>
            
        </Screen>
    );
}
