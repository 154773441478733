import axios from "axios";
import analytics from "helpers/analytics";

const {echoAjaxUrl} = window;

export function fireQuizAnalyticsEvent(action, label) {

  if (label === 1 || label === 0) {
    label = label === 1 ? "true" : "false";
  }

  analytics.fireEvent('assessment2_step_completed', {
    event_value: label,
    event_label: action
  });
}

export function createLead(args, hubspot = true) {
  return axios.post(`${echoAjaxUrl}?action=echo_assessment_quiz_create_lead`, {...args, hubspot});
}

export function createOrder (args) {
  return axios.post(`${echoAjaxUrl}?action=echo_assessment_quiz_create_order`, args);
}

export function sendQuestion (email, content) {
  return axios.post(`${echoAjaxUrl}?action=echo_assessment_quiz_send_question`, {email, content});
}

export function sendAnswer (question, answer, additionalContent) {
  return axios.post(`${echoAjaxUrl}?action=echo_assessment_quiz_send_answer`, {question, answer, additionalContent});
}

export function isOrderProcess () {
  return !!window.assessmentQuizMeta.orderProcess;
}

export function getOrderProcess () {
  return window.assessmentQuizMeta.orderProcess;
}

export function formatPrice (price, decimals = 2) {
  return Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR', maximumFractionDigits: decimals, minimumFractionDigits: decimals}).format(price);
}

export function isEmbed () {
  return window.assessmentQuizMeta.view === 'compact';
}

export function getDiscounts(productId, variationId, qty) {
  if (!isSale()) {
    return 0;
  }

  if (!window.echoSale.discounts[productId][variationId]) {
    return 0;
  }

  return window.echoSale.discounts[productId][variationId][qty];
}

export function isSale() {
  return window.echoSale.isSale;
}
