import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import FormInput from "components/FormInput";

import { useDelayLink } from "helpers/hooks";

import ContinueButton, { useContinueButton } from "features/assessment-quiz/Quiz/components/ContinueButton";
import TextBubble from "features/assessment-quiz/Quiz/components/TextBubble";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";

import styles from "./index.module.scss";
import classNames from "classnames";

const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim('Bitte geben Sie Ihren Vornamen ein').required('Bitte geben Sie Ihren Vornamen ein'),
    lastName: Yup.string().trim('Bitte geben Sie Ihren Vornamen ein').required('Bitte geben Sie Ihren Nachnamen ein'),
});

const genders = [
    { label: 'Herr', value: 'Mann' },
    { label: 'Frau', value: 'Frau' },
]

export default function Name ({ next: nextLink }) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();
    const {getChosenAnswer, setAnswer} = useQuiz();

    const gender = getChosenAnswer('geschlecht') ?? 'Mann';
    const [selectedGender, setSelectedGender] = useState(genders.find((g) => g.value === gender)?.value);

    const name = getChosenAnswer('name') ?? { firstName: '', lastName: '' };
    const form = useFormik({
        initialValues: name,
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            setLoading(true);
            setAnswer({geschlecht: selectedGender, name: values});
            next({ to: nextLink });
        },
    });

    return (
        <div>

            <TextBubble>Bitte geben Sie Ihren Vor- und Nachnamen an.</TextBubble>

            <form onSubmit={form.handleSubmit}>
                <div className={styles.genderSelect}>
                    {genders.map((gender) => (
                        <div
                            key={gender.value}
                            className={classNames(styles.gender, {
                                [styles.selected]: selectedGender === gender.value,
                            })}
                            onClick={() => setSelectedGender(gender.value)}
                        >{gender.label}</div>
                    ))}
                </div>

                <FormInput
                    name='firstName'
                    placeholder='Vorname'
                    form={form}
                    floatingLabel
                />

                <FormInput
                    name='lastName'
                    placeholder='Nachname'
                    form={form}
                    floatingLabel
                />

                <ContinueButton loader={loading} type='submit' />
            </form>

        </div>
    );
}
