import {abEchoClass} from "../../helpers/helpers";

export default function InfoIcon ({size = 20, fill = '#7296B1'}) {
    
    return (

        <svg className={abEchoClass("", "info-icon")} width={size} height={size} viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.1668 9.99937C19.1668 15.0585 15.0602 19.166 10.0002 19.166C4.931 19.166 0.833497 15.0585 0.833496 9.99937C0.833496 4.93845 4.93099 0.832703 10.0002 0.832703C15.0602 0.832702 19.1668 4.93845 19.1668 9.99937ZM10.8068 13.4735C10.8068 13.9126 10.4402 14.2802 10.0002 14.2802C9.56016 14.2802 9.20266 13.9126 9.20266 13.4735V9.42187C9.20266 8.98095 9.56016 8.62437 10.0002 8.62437C10.4402 8.62437 10.8068 8.98095 10.8068 9.42187V13.4735ZM9.991 5.70845C10.4402 5.70845 10.7977 6.07512 10.7977 6.51512C10.7977 6.95512 10.4402 7.31262 10.0002 7.31262C9.551 7.31262 9.19349 6.95512 9.19349 6.51512C9.19349 6.07512 9.551 5.70845 9.991 5.70845Z"
                  fill={fill}></path>
        </svg>
    )
}
