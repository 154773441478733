import React from 'react';
import {createRoot} from 'react-dom/client';

import Loadable from 'react-loadable';
import Loader from "components/Loader";
import Page from "./Page";

const pageNode = document.getElementById('assessment-quiz');

if (pageNode) {

  // const AssessmentQuizPage = Loadable({
  //   loader: () => import('./Page'),
  //   loading: Loader,
  // });

  const root = createRoot(pageNode);
  root.render(<Page />);

}

const shortcodeNodes = document.querySelectorAll('#echo-assessment-quiz-shortcode');

if (shortcodeNodes.length > 0) {

  const AssessmentQuizShortcode = Loadable({
    loader: () => import('./Shortcode'),
    loading: Loader,
  });

  for (const shortcodeNode of shortcodeNodes) {
    const root = createRoot(shortcodeNode);
    root.render(<AssessmentQuizShortcode />);
  }

}

// Hearing aid order process
const haopPageNode = document.getElementById('hearing-aids-order-process');
if (haopPageNode) {

  const HearingAidOrderProcessPage = Loadable({
    loader: () => import('./Page'),
    loading: Loader,
  });

  const root = createRoot(haopPageNode);
  root.render(<HearingAidOrderProcessPage />);
}
