export function findVariation(variations, attrs) {
  return variations.find(variation =>
    Object.entries(variation.attributes).every(([attrKey, attrVal]) => {
      return attrs[attrKey] !== undefined && attrs[attrKey] === attrVal;
    })
  );
}

export function getAttrsFromAnswers(answers) {

  var map = {
    'welche-ohren': {
      attrKey: 'attribute_pa_versorgung',
      valuesMap: {
        'für beide Ohren': 'beide-ohren',
        'nur links': 'linkes-ohr',
        'nur rechts': 'rechtes-ohr'
      }
    },
  };

  var attrs = {};

  Object.entries(map).forEach(([answerKey, attr]) => {
    if (answers[answerKey] !== undefined && attr.valuesMap[answers[answerKey]] !== undefined) {
      attrs[attr.attrKey] = attr.valuesMap[answers[answerKey]];
    }
  });

  return attrs;

}

export function next(conditions, defaultNext) {

  for (const [key, value] of Object.entries(conditions)) {
    if (value === true) {
      return key;
    }
  }

  return defaultNext;

}

export function prepareAnswers(answers) {
  return answers.map(answer => ({
    ...answer,
    value: answer.value !== undefined ? answer.value : answer.text
  }));
}
