import {useEffect, useState} from 'react';

import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';
import AnswersList from 'features/assessment-quiz/Quiz/components/AnswersList';

import Screen from 'features/assessment-quiz/Quiz/components/Screen';
import TextBubble from 'features/assessment-quiz/Quiz/components/TextBubble';
import ContinueButton, { useContinueButton } from 'features/assessment-quiz/Quiz/components/ContinueButton';

import EchoOneEchoProAccum from 'assets/img/assessment-quiz/echo-one-echo-pro-accum.png';
import EchoOneEchoPro from 'assets/img/assessment-quiz/echo-one-echo-pro.png';

import * as Modals from 'features/assessment-quiz/Quiz/modals';

import { useDelayLink } from 'helpers/hooks';

import styles from './index.module.scss';
import config from "../../../../../../config";

const {
    echoProAccumVariationID,
    echoProBatteryVariationID,
    echoProBatteryHighEndVariationID,
    echoProAccumHighEndVariationID,
    echoOneHighEndVariationID,
    echoOneVariationID
} = config;

export default function ChooseDeviceBoth({next: nextLink}) {
    const next = useDelayLink(),
        {getChosenAnswer, setData} = useQuiz(),
        {loading, setLoading} = useContinueButton(),
        [selectedAnswer, setSelectedAnswer] = useState([]),
        ears = getChosenAnswer('welche-ohren') === 'für beide Ohren' ? 'both' : 'one';

    let echoOneAndEchoProAccum = [];
    let echoOneAndEchoPro = [];

    if (getChosenAnswer('technologiestufe') === 'high-end') {
        echoOneAndEchoProAccum = [echoOneHighEndVariationID, echoProAccumHighEndVariationID];
        echoOneAndEchoPro = [echoOneHighEndVariationID, echoProBatteryHighEndVariationID];
    } else if (getChosenAnswer('technologiestufe') === 'premium') {
        echoOneAndEchoProAccum = [echoOneVariationID, echoProAccumVariationID];
        echoOneAndEchoPro = [echoOneVariationID, echoProBatteryVariationID];
    }

    useEffect(() => {
        setSelectedAnswer(echoOneAndEchoProAccum)
    }, []);

    return (
        <Screen className={styles.screen}>

            <TextBubble>Welche beiden Hörgeräte möchten Sie testen?</TextBubble>

            <AnswersList
                variant={`rows highlight_v2 ${styles.answersList}`}
                answers={[
                    {
                        text: <>Echo&nbsp;One & Echo&nbsp;Pro&nbsp;Akku</>,
                        value: echoOneAndEchoProAccum,
                        image: EchoOneEchoProAccum,
                        recommendation: (
                            <>
                                <b>Tipp:</b> Der Akku-Aufpreis lohnt sich. Sie sparen in 6
                                Jahren <b><u>rund {ears === 'both' ? 400 : 200}€</u></b> Batteriekosten.
                            </>
                        ),
                        get highlight() {
                            return JSON.stringify(selectedAnswer) === JSON.stringify(this.value);
                        }
                    },
                    {
                        text: <>Echo&nbsp;One & Echo&nbsp;Pro</>,
                        value: echoOneAndEchoPro,
                        get highlight() {
                            return JSON.stringify(selectedAnswer) === JSON.stringify(this.value);
                        },
                        image: EchoOneEchoPro
                    },
                ]}
                onChoose={(answer) => setSelectedAnswer(answer)}
                chosen={selectedAnswer}
            />

            <ContinueButton disabled={!selectedAnswer} loader={loading} onClick={() => {
                setLoading(true);

                setData((data) => {
                    data.answers.geraetewahl = selectedAnswer;

                    return {
                        ...data,
                        answers: {
                            ...data.answers,
                            geraetewahl: selectedAnswer,
                        }
                    };
                });

                next({to: nextLink}, () => setLoading(false));
            }}/>

            <Modals.HearingAids/>

        </Screen>
    )
}
