import { useState } from "react";
import classNames from "classnames";

import Button from "components/Button";
import ArrowRight from "components/Icon/ArrowRight";

import styles from "./index.module.scss";

export function useContinueButton () {
    const [loading, setLoading] = useState(false);

    return {
        loading,
        setLoading,
    }
}

export default function ContinueButton (rest) {
    return (
        <Button
            {...rest}
            className={classNames(rest.className, styles.button)}
            onClick={() => {
                rest.onClick && rest.onClick();
            }}
        >
            Weiter <ArrowRight size='12' />
        </Button>
    )
}
