import {useEffect, useState} from 'react';

import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';

import Screen from 'features/assessment-quiz/Quiz/components/Screen';
import TextBubble from 'features/assessment-quiz/Quiz/components/TextBubble';
import ContinueButton, { useContinueButton } from 'features/assessment-quiz/Quiz/components/ContinueButton';
import AnswersList from 'features/assessment-quiz/Quiz/components/AnswersList';
import ProductCard from 'features/assessment-quiz/Quiz/components/ProductCard';

import * as Modals from 'features/assessment-quiz/Quiz/modals';

import config from "../../../../../../config";
import hearingAids, { getRecommendedProduct } from 'features/assessment-quiz/data/hearingAids';
import { getOrderProcess, isOrderProcess } from 'features/assessment-quiz/helpers';

import { useDelayLink } from 'helpers/hooks';

import styles from './index.module.scss';

const {
    echoProAccumVariationID,
    echoProBatteryVariationID,
    echoOneAkkuHighEndVariationID
} = config;

export default function ChooseDevice ({next: nextLink}) {
    const {answers, setAnswer, getChosenAnswer, setAdditionalAnswer} = useQuiz();
    const EchoProAkku = hearingAids.find(product => product.id === echoProAccumVariationID);
    const recommendedProduct = isOrderProcess() ? EchoProAkku : getRecommendedProduct(answers);
    const [choosenAttribute, setChoosenAttribute] = useState(getChosenAnswer('technologiestufe'));
    const {loading, setLoading} = useContinueButton();
    const next = useDelayLink();

    const [selectedProduct, setSelectedProduct] = useState(
        hearingAids.find(product => product.technologyLevel[choosenAttribute] === getChosenAnswer("geraetewahl")) ||
        recommendedProduct,
    );

    // Order process data
    if ( isOrderProcess() ) {
        const { hearingAid } = getOrderProcess();
        if ( hearingAid === echoProAccumVariationID || hearingAid === echoProBatteryVariationID ) {
            delete hearingAids[0];
        }
    }

    const handleProductClick = (product) => {
        setSelectedProduct(product);

        setAnswer('geraetewahl', product.technologyLevel[choosenAttribute]);
        setAdditionalAnswer('geraetename', product.name);
    }

    const handleAttributeClick = (attributeSlug) => {
        setAnswer({
            technologiestufe: attributeSlug,
            geraetewahl: selectedProduct.technologyLevel[attributeSlug]
        });

        setChoosenAttribute(attributeSlug);
    };

    const attributeTabsData = [
        {
            name: 'Premium',
            slug: 'premium',
        },
        {
            name: 'High-End',
            slug: 'high-end',
        }
    ]

    return (
        <Screen className={styles.screen}>

            <TextBubble>Welche Hörgeräte möchten Sie testen?</TextBubble>

            <div className={styles.attributeTabs}>
                <ul>
                    {attributeTabsData.map((item) => (
                        <li
                            key={item.slug}
                            className={choosenAttribute === item.slug ? styles.activeAttribute : ""}
                            onClick={() => handleAttributeClick(item.slug)}
                        >
                            {item.name}
                        </li>
                    ))}
                </ul>
            </div>

            {
                hearingAids
                    .sort((a, b) => (a.name === recommendedProduct) ? -1 : (b.name === recommendedProduct) ? 1 : 0)
                    .map((product) => {
                        const isSelected = selectedProduct.technologyLevel[choosenAttribute] === product.technologyLevel[choosenAttribute];
                        const isRecommended = recommendedProduct.technologyLevel[choosenAttribute] === product.technologyLevel[choosenAttribute];

                        switch (choosenAttribute) {
                            case "premium":
                                if (product.id === echoOneAkkuHighEndVariationID) {
                                    return null;
                                }
                                break;
                            default:
                                break;
                        }

                        return (
                            <ProductCard
                                onClick={() => handleProductClick(product)}
                                product={product}
                                key={product.name}
                                recommended={!isOrderProcess() ? isRecommended : (isRecommended && (
                                    <span><b>Empfohlen:</b> Sie sparen rund 200€ Batteriekosten pro Gerät über 6 Jahre (mehr als der Akku-Aufpreis).</span>
                                ))}
                                selected={isSelected}
                            />
                        );
                    }
                )
            }

            <p className={styles.info}>Der “Eigenanteil bei Kauf” wird erst fällig, wenn Sie sich nach der Testphase <b>aktiv</b> für die Hörgeräte entscheiden. Die Testphase ist 100% kostenlos und unverbindlich.</p>

            <ContinueButton disabled={!selectedProduct} loader={loading} onClick={() => {
                setLoading(true);
                setAdditionalAnswer('geraetename', selectedProduct.name);
                setAnswer('geraetewahl', selectedProduct.technologyLevel[choosenAttribute]);

                next({ to: nextLink }, () => setLoading(false));
            }} />

            <Modals.HearingAids />

            {!isOrderProcess() && <div className={styles.unsure}>
                <h3>Unsicher bei der Gerätewahl?</h3>

                <AnswersList
                    variant='small last_big'
                    answers={[
                        { text: 'Echo One und Pro gleichzeitig testen', value: '', next: '/geraetewahl-beide' },
                    ]}
                    returnType='object'
                    onChoose={(answer) => {
                        setAnswer('geraetewahl', answer.value);
                        next({ to: answer.next });
                    }}
                    chosen={getChosenAnswer('geraetewahl')}
                />
            </div>}

        </Screen>
    )
}
