import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import axios from 'axios';
import classNames from 'classnames';

import { useDelayLink } from 'helpers/hooks';
import config from 'config';
import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';
import { createLead } from 'features/assessment-quiz/helpers';

import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import ContinueButton, { useContinueButton } from 'features/assessment-quiz/Quiz/components/ContinueButton';
import TextBubble from 'features/assessment-quiz/Quiz/components/TextBubble';

import inputStyles from 'components/Input/index.module.scss';
import styles from './index.module.scss';

const validationSchema = Yup.object().shape({
    street: Yup.string().required('Bitte geben Sie Ihre Straße ein'),
    houseNumber: Yup.string()
        .matches(/^[\w/-]+$/, 'Bitte geben Sie eine gültige Hausnummer ein')
        .required('Bitte geben Sie Ihre Hausnummer ein'),
    zip: Yup.string()
        .matches(/^[0-9]{5}$/, 'Bitte geben Sie eine gültige Postleitzahl ein')
        .required('Bitte geben Sie Ihre Postleitzahl ein'),
    city: Yup.string().required('Bitte geben Sie Ihre Stadt ein'),
});

const { shippingCountries } = window.assessmentQuizMeta;

export default function Address ({ next: nextLink }) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();
    const {setAnswer} = useQuiz();

    const [enteredAddress, setEnteredAddress] = useState(window.echoAbSettings['manual_address']);
    const form = useFormik({
        initialValues: {
			country: 'DE',
            street: '',
            houseNumber: '',
            zip: '',
            city: '',
        },
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
	    onSubmit: async (values) => {
		    setLoading(true);
		    const { street, houseNumber, zip, city, country } = values;

		    const address = `${street} ${houseNumber}, ${zip} ${city}, ${country}`;

		    const { data } = await axios.post(`${window.echoAjaxUrl}?action=echo_assessment_quiz_get_lat_lng`, {
			    address,
		    });

		    const { lat, lng } = data.data;

		    setAnswer("adresse", { street, houseNumber, zip, city, country, lat, lng }, (answers) => {
			    createLead({ ...answers, hubspot_exitName: "Anmeldung zum Probehören" });
		    });

		    next({ to: nextLink }, () => setLoading(false));
	    },
    });

    function onPlaceSelected ({address_components}) {
        let street = address_components.find(({types}) => types.includes('route')),
            houseNumber = address_components.find(({types}) => types.includes('street_number')),
            zip = address_components.find(({types}) => types.includes('postal_code')),
            city = address_components.find(({types}) => types.includes('locality')),
	        country = address_components.find(({types}) => types.includes('country'));

        street = street ? street.long_name : '';
        houseNumber = houseNumber ? houseNumber.long_name : '';
        zip = zip ? zip.long_name : '';
        city = city ? city.long_name : '';
	    country = country ? country.short_name.toUpperCase() : form.values.country;

	    form.setValues({ street, houseNumber, zip, city, country });

        setEnteredAddress(true);
    }

    return (
        <div>

            <TextBubble>An welche Adresse sollen wir die Hörgeräte für das kostenlose Probetragen schicken?</TextBubble>

	        <FormSelect
		        name="country"
		        options={[...shippingCountries]}
		        form={form}
	        />

	        {!enteredAddress && (
		        <ReactGoogleAutocomplete
			        className={inputStyles.input} style={{ width: "100%" }}
			        apiKey={config.googleApiKey}
			        onPlaceSelected={(place) => onPlaceSelected(place)}
			        options={{
				        types: ["address"],
				        componentRestrictions: { country: form.values.country.toLowerCase() },
			        }}
		        />
	        )}

            {enteredAddress && (
                <form onSubmit={form.handleSubmit} className={styles.form}>
                    <div className={styles.inputGroup}>
                        <FormInput
                            name='street'
                            placeholder='Straße'
                            form={form}
                            floatingLabel
                        />

                        <FormInput
                            name='houseNumber'
                            placeholder='Hausnummer'
                            form={form}
                            floatingLabel
                        />
                    </div>

                    <div className={classNames(styles.inputGroup, styles.reversed)}>
                        <FormInput
                            name='zip'
                            placeholder='PLZ'
                            form={form}
                            floatingLabel
                        />

                        <FormInput
                            name='city'
                            placeholder='Stadt'
                            form={form}
                            floatingLabel
                        />
                    </div>

                    <ContinueButton loader={loading} type='submit' />
                </form>
            )}
        </div>
    );
}
