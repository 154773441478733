import React from 'react';

import classNames from 'classnames';

import styles from './index.module.scss';
import {abEchoClass, sanitizeTextForClassName} from "../../../helpers/helpers";

const Heading = ({className, children}) => (
  <b className={classNames(styles['heading'], className, abEchoClass('heading', sanitizeTextForClassName(children, true)))}>{children}</b>
);

export default Heading;
