import React, {useState} from 'react';
import classNames from 'classnames';
import Modal from "../Modal";

import styles from './index.module.scss';
import {abEchoClass} from "../../helpers/helpers";

const LinkModal = ({linkText, modalTitle = '', modalType, modalClassName, linkClassName, ...rest}) => {

  const [isOpen, setIsOpen] = useState(false);

    const handleModalContainerClick = (e) => {
        e.stopPropagation();
    };

  function openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  }

  function closeModal(e) {
      e.stopPropagation();
      setIsOpen(false);
  }

    let linkTextContent = '';

    if (linkText.props && linkText._owner && Object.keys(linkText.props).length === 0) {
        linkTextContent = linkText._owner?.key
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/-+$/, '');
    } else if (typeof linkText === 'object' && linkText.props && Array.isArray(linkText.props.children)) {
        linkTextContent = linkText.props.children
            .filter(child => typeof child === 'string')
            .join(' ')
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/-+$/, ''); // Remove trailing '-' characters
    } else if (typeof linkText === 'string') {
        linkTextContent = linkText
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/-+$/, ''); // Remove trailing '-' characters
    }

  return (
    <>
      <a href="#" onClick={openModal} className={classNames(styles['link'], abEchoClass('link-modal', linkTextContent), linkClassName)}>{linkText}</a>
      <Modal
        onClick={handleModalContainerClick}
        isOpen={isOpen}
        onRequestClose={closeModal}
        title={modalTitle}
        className={classNames(modalClassName, {
          [styles['modal-simple']]: modalType === 'simple'
        })}
        {...rest}
      />
    </>
  );
}

export default LinkModal;
