import config from "../../../config";

import SaleCountdownBox from "../../sale-countdown-box";

import EchoOneImage from "assets/img/assessment-quiz/echo-one.png";
import EchoOneAkkuImage from "assets/img/assessment-quiz/echo-one-akku.png";
import EchoProImage from "assets/img/assessment-quiz/echo-pro.png";
import EchoProAccumImage from "assets/img/assessment-quiz/echo-pro-accum.png";
import EchoTechnologiestufeImage from "assets/img/assessment-quiz/echo-technologiestufe.png";
import StarIcon from "assets/icons/star.svg";

const {
	echoOneProductID,
	echoProProductID,
	echoOneVariationID,
	echoOneHighEndVariationID,
	echoOneAkkuHighEndVariationID,
	echoProAccumVariationID,
	echoProAccumHighEndVariationID,
	echoProBatteryVariationID,
	echoProBatteryHighEndVariationID,
} = config;

const hearingAids = [
    {
        name: 'Echo Pro Akku',
        type: '',
        id: echoProAccumVariationID,
	    parentId: echoProProductID,
        name_html: (<>Echo&nbsp;Pro&nbsp;Akku</>),
        image: EchoProAccumImage,
        technologyLevel: {
            premium: echoProAccumVariationID,
            'high-end': echoProAccumHighEndVariationID,
        },
        ownContribution: {
            both: '325-370€',
            one: '175-190€',
        },
        price: '',
        comparisonPrice: {},
	    saleCountDown: <SaleCountdownBox size="sm" />,
        recommendations: [
            'Sprachfokus für klares Sprachverstehen',
            'nahezu unsichtbar im Ohr',
            'App-Steuerung',
            'Bluetooth & Streaming',
            'Akku: 3 Std. laden, 24. Std hören',
            (answers) => {
                return `über 6 Jahre sparen Sie rund ${answers['welche-ohren'] === 'für beide Ohren' ? 400 : 200} € Batteriekosten`;
            },],
    },
    {
        name: 'Echo Pro',
        type: '',
        id: echoProBatteryVariationID,
	    parentId: echoProProductID,
	    name_html: (<>Echo&nbsp;Pro</>),
        image: EchoProImage,
        technologyLevel: {
            premium: echoProBatteryVariationID,
            'high-end': echoProBatteryHighEndVariationID,
        },
        ownContribution: {
            both: 'Nulltarif&nbsp;(25-70€)',
            one: 'Nulltarif&nbsp;(25-40€)',
        },
        price: {},
        comparisonPrice: {},
        recommendations: [
            'Sprachfokus für klares Sprachverstehen',
            'nahezu unsichtbar im Ohr',
            'App-Steuerung',
            'Bluetooth & Streaming',
        ],
    },
    {
        name: 'Echo One',
        type: '',
        id: echoOneVariationID,
	    parentId: echoOneProductID,
        name_html: (<>Echo&nbsp;One</>),
        image: EchoOneImage,
        technologyLevel: {
            premium: echoOneVariationID,
            'high-end': echoOneHighEndVariationID,
        },
        ownContribution: {
            both: 'Nulltarif&nbsp;(25-70€)', one: 'Nulltarif&nbsp;(25-40€)',
        },
        price: {},
        comparisonPrice: {},
        recommendations: ['Sprachfokus für klares Sprachverstehen', 'nahezu unsichtbar im Ohr', 'App-Steuerung',],
    },
    {
        name: 'Echo One Akku',
        type: '',
        id: echoOneAkkuHighEndVariationID,
	    parentId: echoOneProductID,
        name_html: (<>Echo&nbsp;One Akku</>),
        image: EchoOneAkkuImage,
        technologyLevel: {
            'high-end': echoOneAkkuHighEndVariationID,
        },
        ownContribution: {
            both: 'Nulltarif&nbsp;(25-70€)', one: 'Nulltarif&nbsp;(25-40€)',
        },
        price: {},
        comparisonPrice: {},
        recommendations: ['Sprachfokus für klares Sprachverstehen', 'nahezu unsichtbar im Ohr', 'App-Steuerung',],
    }
];

// Attributes
const Attributes = {
	technologiestufe: [{
		name: "Basis-Klasse",
		name_html: (<>Basis-Klasse</>),
		disabled: true,
		staticText: "Bieten wir online nicht an, da unser Nulltarif bereits die Premium-Klasse enthält.",
		ratingStars: 2,
	}, {
		name: "Premium-Klasse",
		tabName: "Premium",
		type: "premium",
		name_html: (<>Premium-Klasse</>),
		image: EchoTechnologiestufeImage,
		ownContribution: {
			both: "Nulltarif&nbsp;(20-70€)",
			one: "Nulltarif&nbsp;(20-40€)",
		},
		price: {},
		referencePrices: {},
		recommendations: [
			"Sprachfokus für klares Sprachverstehen",
			"nahezu unsichtbar im Ohr",
			"App-Steuerung",
			"Bluetooth & Streaming",
		],
		ratingStars: 4,
	}, {
		name: "High-End-Klasse",
		tabName: "High-End",
		type: "high-end",
		name_html: (<>High-End-Klasse</>),
		image: EchoTechnologiestufeImage,
		ownContribution: {
			both: "ab 1290€",
			one: "ab 2580€",
		},
		price: "",
		referencePrices: {},
		recommendationsTitle: "Vorteile der High-End-Klasse:",
		recommendations: [
			<>noch müheloser verstehen in <strong>Gruppengesprächen</strong></>,
			<>besseres <strong>Richtungshören</strong> durch adaptive Mikrofontechnik</>,
			<>satter und natürlicher Klang beim <strong>Musikhören</strong> durch erweiterten Frequenzbereich</>,
		],
		ratingStars: 5,
	}],
};

const formatPrice = (price) => {
	return new Intl.NumberFormat("de-DE").format(price); // Adjust 'de-DE' to your locale if needed
};

function renderPriceText(product) {

	const variationExtraPrices = window.assessmentQuizMeta.variations[product.id].variationExtraPrices;

	let technologiestufeExtraPriceAkku = variationExtraPrices.technologiestufeExtraPrice["akku"];
	let technologiestufeExtraPriceBattery = variationExtraPrices.technologiestufeExtraPrice["batterie"];

	let technologiestufeExtraPriceEchoOne = variationExtraPrices.technologiestufeExtraPrice["echo-one"];

	let accumulatorExtraPricePremium = variationExtraPrices.accumulatorExtraPrice["premium"];
	let accumulatorExtraPriceHighEnd = variationExtraPrices.accumulatorExtraPrice["high-end"];

	let accumulatorExtraPriceEchoOneHighEndAkku = variationExtraPrices.accumulatorExtraPrice["echo-one-high-end-akku"];


	return {
		technologiestufeExtraPriceAkku,
		technologiestufeExtraPriceBattery,
		accumulatorExtraPricePremium,
		accumulatorExtraPriceHighEnd,
		technologiestufeExtraPriceEchoOne,
		accumulatorExtraPriceEchoOneHighEndAkku,
	};
}

function calculateHighEndOwnContribution(isEchoPro, isEchoOne, isEchoOneHighEndAkku, extraPrices, item, qty) {
	let ownContributionOne, ownContributionBoth, ownContributionBothDiscount, ownContributionOneDiscount;

	if (isEchoPro) {
		ownContributionOne = `${formatPrice(extraPrices.technologiestufeExtraPriceBattery + 25)}-${formatPrice(extraPrices.technologiestufeExtraPriceBattery + 40)}`;
		ownContributionBoth = `${formatPrice((extraPrices.technologiestufeExtraPriceBattery * qty) + 25)}-${formatPrice((extraPrices.technologiestufeExtraPriceBattery * qty) + 70)}`;
	} else if (isEchoOne) {
		ownContributionOne = `${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 25)}-${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 40)}`;
		ownContributionBoth = `${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne * qty + 25)}-${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne * qty + 70)}`;
	} else if (isEchoOneHighEndAkku) {
		ownContributionOneDiscount = `${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 25 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty] - getDiscounts(item.technologyLevel["high-end"]))}-${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 40 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty] - getDiscounts(item.technologyLevel["high-end"]))}`;
		ownContributionBothDiscount = `${formatPrice((extraPrices.technologiestufeExtraPriceEchoOne * qty) + 25 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty] - (getDiscounts(item.technologyLevel["high-end"]) * 2))}-${formatPrice((extraPrices.technologiestufeExtraPriceEchoOne * qty) + 70 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty] - (getDiscounts(item.technologyLevel["high-end"]) * 2))}`;

		ownContributionOne = `${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 25 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty])}-${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 40 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty])}`;
		ownContributionBoth = `${formatPrice((extraPrices.technologiestufeExtraPriceEchoOne * qty) + 25 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty])}-${formatPrice((extraPrices.technologiestufeExtraPriceEchoOne * qty) + 70 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty])}`;
	} else {
		ownContributionOneDiscount = `${formatPrice(extraPrices.technologiestufeExtraPriceAkku + 25 + extraPrices.accumulatorExtraPriceHighEnd[qty] - getDiscounts(item.technologyLevel["high-end"]))}-${formatPrice(extraPrices.technologiestufeExtraPriceAkku + 40 + extraPrices.accumulatorExtraPriceHighEnd[qty] - getDiscounts(item.technologyLevel["high-end"]))}`;
		ownContributionBothDiscount = `${formatPrice((extraPrices.technologiestufeExtraPriceAkku * qty) + 25 + extraPrices.accumulatorExtraPriceHighEnd[qty] - (getDiscounts(item.technologyLevel["high-end"]) * 2))}-${formatPrice((extraPrices.technologiestufeExtraPriceAkku * qty) + 70 + extraPrices.accumulatorExtraPriceHighEnd[qty] - (getDiscounts(item.technologyLevel["high-end"]) * 2))}`;

		ownContributionOne = `${formatPrice(extraPrices.technologiestufeExtraPriceAkku + 25 + extraPrices.accumulatorExtraPriceHighEnd[qty])}-${formatPrice(extraPrices.technologiestufeExtraPriceAkku + 40 + extraPrices.accumulatorExtraPriceHighEnd[qty])}`;
		ownContributionBoth = `${formatPrice((extraPrices.technologiestufeExtraPriceAkku * qty) + 25 + extraPrices.accumulatorExtraPriceHighEnd[qty])}-${formatPrice((extraPrices.technologiestufeExtraPriceAkku * qty) + 70 + extraPrices.accumulatorExtraPriceHighEnd[qty])}`;
	}

	return {
		discounts: {
			one: ownContributionOneDiscount,
			both: ownContributionBothDiscount,
		},
		one: ownContributionOne,
		both: ownContributionBoth,
	};
}

function getDiscounts(variation_id) {
	return window.assessmentQuizMeta.variations[variation_id]?.discounts;
}

hearingAids.map((item, key) => {
	const variation = window.assessmentQuizMeta.variations[item.id];
	const productsData = window.assessmentQuizMeta.products;

	const comparisonPrice = variation.comparisonPrice.prices;
	const energySource = variation.energySource;

	const extraPrices = renderPriceText(item);

	const isEchoPro = item.id === echoProBatteryVariationID;
	const isEchoAkkum = item.id === echoProAccumVariationID;
	const isEchoOne = item.id === echoOneVariationID;
	const isEchoOneHighEndAkku = item.id === echoOneAkkuHighEndVariationID;

	const ownContributionHighEnd = calculateHighEndOwnContribution(isEchoPro, isEchoOne, isEchoOneHighEndAkku, extraPrices, item, 1);
	const ownContributionHighEndBoth = calculateHighEndOwnContribution(isEchoPro, isEchoOne, isEchoOneHighEndAkku, extraPrices, item, 2);

	hearingAids[key] = {
		...hearingAids[key],
		comparisonPrice: {
			"discounts": {
				"premium": {
					one: `${formatPrice(comparisonPrice.premium - getDiscounts(item.technologyLevel["premium"]))}€`,
					both: `${formatPrice(comparisonPrice.premium * 2 - (getDiscounts(item.technologyLevel["premium"]) * 2))}€`,
				},
				"high-end": {
					one: `${formatPrice(comparisonPrice["high-end"] - getDiscounts(item.technologyLevel["high-end"]))}€`,
					both: `${formatPrice(comparisonPrice["high-end"] * 2 - (getDiscounts(item.technologyLevel["high-end"]) * 2))}€`,
				},
			},
			"premium": {
				one: `${formatPrice(comparisonPrice.premium)}€`,
				both: `${formatPrice(comparisonPrice.premium * 2)}€`,
			},
			"high-end": {
				one: `${formatPrice(comparisonPrice["high-end"])}€`,
				both: `${formatPrice(comparisonPrice["high-end"] * 2)}€`,
			},
		},
		ownContribution: {
			"discounts": {
				"premium": {
					one: `${!isEchoAkkum ? `Nulltarif (25-40€)` : `${extraPrices.accumulatorExtraPricePremium[1] + 25 - getDiscounts(item.technologyLevel["premium"])}-${extraPrices.accumulatorExtraPricePremium[1] + 40 - getDiscounts(item.technologyLevel["premium"])}€`}`,
					both: `${!isEchoAkkum ? `Nulltarif* (25-70€)` : `${extraPrices.accumulatorExtraPricePremium[2] + 25 - (getDiscounts(item.technologyLevel["premium"]) * 2)}-${extraPrices.accumulatorExtraPricePremium[2] + 70 - (getDiscounts(item.technologyLevel["premium"]) * 2)}€`}`,
				},
				"high-end": {
					one: `${ownContributionHighEnd.discounts.one}€`,
					both: `${ownContributionHighEndBoth.discounts.both}€`,
				},
			},
			"premium": {
				one: `${!isEchoAkkum ? `Nulltarif (25-40€)` : `${extraPrices.accumulatorExtraPricePremium[1] + 25}-${extraPrices.accumulatorExtraPricePremium[1] + 40}€`}`,
				both: `${!isEchoAkkum ? `Nulltarif* (25-70€)` : `${extraPrices.accumulatorExtraPricePremium[2] + 25}-${extraPrices.accumulatorExtraPricePremium[2] + 70}€`}`,
			},
			"high-end": {
				one: `${ownContributionHighEnd.one}€`,
				both: `${ownContributionHighEndBoth.both}€`,
			},
		},
		productData: productsData,
		type: energySource,
		price: variation.price,
	};

	return key;
});

const getRatingStars = (rating) => {
	let stars = [];
	for (let i = 0; i < rating; i++) {
		stars.push(<img src={StarIcon} alt="Star" key={i} />);
	}
	return stars;
};

Attributes.technologiestufe.forEach(attribute => {
	const echoOneComparisonPrice = hearingAids.find(product => product.id === echoOneVariationID);
	const echoProHighEndPrice = window.assessmentQuizMeta.variations[echoProBatteryHighEndVariationID];
	const echoProPremiumPrice = window.assessmentQuizMeta.variations[echoProBatteryVariationID];

	if (echoOneComparisonPrice && echoProHighEndPrice && echoProPremiumPrice) {

		attribute.starElements = getRatingStars(attribute.ratingStars);

		if (!attribute.disabled) {
			attribute.referencePrices = echoOneComparisonPrice.comparisonPrice;

			if (attribute.type === "high-end") {
				attribute.ownContribution = {
					one: `ab ${(echoProHighEndPrice.price - echoProPremiumPrice.price) + 25}€`,
					both: `ab ${((echoProHighEndPrice.price - echoProPremiumPrice.price) * 2) + 25}€`,
				};
			}
		}
	}
});


export default hearingAids;

export { Attributes };

export function getRecommendedProduct(answers) {
	const EchoOne = hearingAids.find(product => product.id === echoOneVariationID);
	const EchoProAccum = hearingAids.find(product => product.id === echoProAccumVariationID);


	if (answers["wichtig-bei-neuen-hg"] === "Unauffälliges Design") {
		return EchoOne;
	}

	return EchoProAccum;
}
