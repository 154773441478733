import {useLocation} from 'react-router-dom';
import classNames from "classnames";
import styles from "./index.module.scss";

import {abEchoClass, sanitizeTextForClassName} from "../../helpers/helpers";

const Rating = () => {
    const location = useLocation();

    return (
        <div className={classNames(styles['container'], 'big-rating', abEchoClass('rating', sanitizeTextForClassName(location.pathname)))}>
            <etrusted-widget data-etrusted-widget-id="wdg-76ae3931-a4e9-4d67-af8e-b37b147244e2"></etrusted-widget>
        </div>
    )
}

export default Rating;
