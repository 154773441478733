import React, { useState } from "react";

import styles from "./index.module.scss";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const Notice = ({ success, message, link }) => {
	const [close, setClose] = useState(true);

	if (!close) return null;

	return (
		<div className={styles.notice}>

			<div style={{ color: success ? "#1E834D" : "#E23F1C", display: "flex", gap: "5px" }}>
				{success ? "" : <ErrorIcon />} <p style={{ marginBottom: 0 }}>{message} {link}</p>
			</div>

			<span
				onClick={() => setClose(false)}
				className={styles.close}>
				<CloseIcon />
			</span>
		</div>
	);
};

export default Notice;
