import { useFormik } from 'formik';
import * as Yup from 'yup';

import Screen from '../../components/Screen';
import TextBubble from '../../components/TextBubble';
import FormTextarea from 'components/FormTextarea';
import FormInput from 'components/FormInput';
import ContinueButton, { useContinueButton } from '../../components/ContinueButton';

import { createLead, sendQuestion } from 'features/assessment-quiz/helpers';
import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';

import styles from './index.module.scss';
import { useDelayLink } from 'helpers/hooks';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Bitte geben Sie Ihre E-Mail-Adresse ein').required('Bitte geben Sie Ihre E-Mail-Adresse ein'),
    message: Yup.string(),
});

export default function InfoPackage ({next: nextLink}) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();

    const {answers, resetQuiz} = useQuiz();

    const form = useFormik({
        initialValues: {
            email: '',
            message: '',
        },
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async (values) => {
            setLoading(true);

            if (values.message) {
                await sendQuestion(values.email, values.message);
            }

            await createLead({
                ...answers,
                'frage-per-mail': values.message,
                'infopaket_angefordert': 'Ja',
                event: 'Infopaket angefordert (BIG)',
                kontaktdaten: { ...answers.kontaktdaten, email: values.email }
            });
            
            resetQuiz();
            next({ to: nextLink });
        },
    });

    return (
        <Screen>

            <TextBubble>Gerne schicken wir Ihnen ein kostenloses Infopaket zu. Haben Sie noch konkrete Fragen?</TextBubble>

            <form onSubmit={form.handleSubmit}>
                <FormTextarea
                    name='message'
                    placeholder='Fragen eingeben... (optional)'
                    form={form}
                    floatingLabel
                />
                <FormInput
                    name='email'
                    placeholder='Ihre E-Mail-Adresse *'
                    form={form}
                    floatingLabel
                />

                <ContinueButton loader={loading} type='submit' />
            </form>

            <div className={styles.info}>
                <p>Sie erhalten sofort ein Infopaket per E-Mail und einer unserer Hörexperten wird Ihre konkreten Fragen beantworten.</p>

                <small className={styles.privacyNotice}>
                    Bitte beachten Sie unsere <a href="/datenschutz" target='_blank'>Datenschutzhinweise</a>
                </small>
            </div>

        </Screen>
    )
}
