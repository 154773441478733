import React from 'react';

import {abEchoClass, sanitizeTextForClassName} from "../../helpers/helpers";

import classNames from 'classnames';

import styles from './index.module.scss';

const Select = ({options, className, ...rest}) => {
  const location = window.location;

  options = options.map(option => {
    return typeof option === "string" || typeof option === "number" ? {value: option, label: option} : option;
  });

  return (
    <select className={classNames(className, styles.select, abEchoClass('select', sanitizeTextForClassName(location.pathname)))} {...rest}>
      {options.map(({value, label}) =>
        <option value={value} key={value} className={abEchoClass('option', sanitizeTextForClassName(value))}>{label}</option>
      )}
    </select>
  );
};

export default Select;
