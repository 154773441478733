import styles from './index.module.scss';

const { footer_icons } = window.assessmentQuizMeta;

export default function Footer () {

    return (
        <footer className={styles.footer}>
            <div className={styles.footerTop}>
                <div className={styles.footerTopImages} dangerouslySetInnerHTML={{ __html: footer_icons }} />
            </div>
            <div className={styles.footerNav}>
                <a href='/impressum'>Impressum</a>
                <a href='/datenschutzerklaerung'>Datenschutz</a>
                <a href='/agb'>AGB</a>
            </div>
        </footer>
    );
}
