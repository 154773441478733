import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import ContinueButton, { useContinueButton } from 'features/assessment-quiz/Quiz/components/ContinueButton';

import FormTextarea from 'components/FormTextarea';

import { useQuiz } from 'features/assessment-quiz/Page/QuizContext';

import { useDelayLink } from 'helpers/hooks';

import Question from 'features/assessment-quiz/Quiz/components/Question';
import Screen from 'features/assessment-quiz/Quiz/components/Screen';

import styles from './index.module.scss';

const validationSchema = Yup.object().shape({
    message: Yup.string().required('Bitte geben Sie einen Grund an'),
})

export default function WhyNoHearingAids () {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();

    const { setAnswer, getChosenAnswer } = useQuiz();
    const form = useFormik({
        initialValues: {
            message: '',
        },
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            setLoading(true);
            setAnswer('warum-tragen-sie-hg-nicht', values.message);
            next({ to: '/info-hg-sollte-man-tragen' });
        }
    });

    const [selectedOther, setSelectedOther] = useState(false);

    return (
        <Screen>

            <Question
                question='Aus welchem Grund tragen Sie Ihre Hörgeräte nicht regelmäßig?'
                answers={[
                    { text: 'Sie sind mir peinlich' },
                    { text: 'Sie sind unbequem' },
                    { text: 'Sie helfen nicht wie erhofft' },
                    { text: 'Sonstiges', highlight: selectedOther },
                ]}
                onChoose={(answer) => {
                    setSelectedOther(answer === 'Sonstiges');
                    if (answer === 'Sonstiges') {
                        return;
                    }

                    setAnswer('warum-tragen-sie-hg-nicht', answer);
                    next({ to: '/info-hg-sollte-man-tragen' });
                }}
                chosen={getChosenAnswer('warum-tragen-sie-hg-nicht')}
            />

            {selectedOther && (
                <form className={styles.other}
                    onSubmit={form.handleSubmit}
                >
                    <FormTextarea
                        name='message'
                        form={form}
                        floatingLabel
                        placeholder='Der Grund ist...' />

                    <ContinueButton loader={loading} type='submit' />
                </form>   
            )}

        </Screen>
    );
}
