import {useLocation} from "react-router-dom";
import classNames from "classnames";

import { useDelayLink } from "helpers/hooks";
import { prepareAnswers } from "helpers/quiz";

import AnswersList from "../AnswersList";


import TextBubble from "../TextBubble";
import {abEchoClass, sanitizeTextForClassName} from "../../../../../helpers/helpers";

export default function Question ({ variant, question, answers, onChoose, chosen, next, returnType = 'value', TextBubbleClass}) {
    const location = useLocation();

    const preparedAnswers = prepareAnswers(answers);
    const goTo = useDelayLink();

    function onChooseHandler (item) {
        let value = item.value;
        if (returnType === 'value') {
            value = item;
        }

        const chosenAnswer = preparedAnswers.find(answer => answer.value === value);

        onChoose && onChoose(item);

        if (chosenAnswer.next || next) {
            let to = chosenAnswer.next !== undefined ? chosenAnswer.next : next;

            if (typeof to === 'function') {
                to = to(item);
            }

            goTo({ to, delay: 500 });
        }
    }

    return (
        <div className={classNames(abEchoClass('question', sanitizeTextForClassName(location.pathname)))}>
            <TextBubble className={TextBubbleClass}>{question}</TextBubble>

            <AnswersList
                variant={variant}
                answers={preparedAnswers}
                onChoose={onChooseHandler}
                chosen={chosen}
                returnType={returnType} />
        </div>
    )
}
