import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { sendAnswer } from "features/assessment-quiz/helpers";

import AnswersList from "../../components/AnswersList";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import TextBubble from "../../components/TextBubble";
import ContinueButton, { useContinueButton } from "../../components/ContinueButton";
import FormTextarea from "../../../../../components/FormTextarea";

import styles from "./index.module.scss";
import Loading from "../../components/Loading";
import classNames from "classnames";

const surveyList = [
	{
		text: "Facebook",
		value: "facebook",
	},
	{
		text: "Instagram",
		value: "instagram",
	},
	{
		text: "Google",
		value: "google",
	},
	{
		text: "Nachrichtenseite (ntv, t-online, ..)",
		value: "news",
	},
	{
		text: "Youtube",
		value: "youtube",
	},
	{
		text: "Freunde oder Verwandte",
		value: "friends",
	},
	{
		text: "TV-Werbung",
		value: "tv",
	},
	{
		text: "per Post / Brief",
		value: "post",
	},
	{
		text: "1. FC Gievenbeck Sponsor",
		value: "fc_gievenbeck",
	},
	{
		text: "Sonstige",
		value: "other",
	},
];

export default function Survey() {
	const orderData = JSON.parse(sessionStorage.getItem("order_data"));
	const [selectedOther, setSelectedOther] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState(false);
	const { loading, setLoading } = useContinueButton();

	const validationSchema = Yup.object().shape({
		message: Yup.string().required("Bitte geben Sie einen Grund an"),
	});

	const form = useFormik({
		initialValues: {
			message: "",
		},
		validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: async (values) => {
			setLoading(true);
			const { data } = await sendAnswer("pps", `other: ${values.message}`, {
				order_id: orderData.order_id,
			});

			if (data.success) {
				window.location.href = orderData.url;
			}

			sessionStorage.removeItem("order_data");
		},
	});

	return (
		<div>
			{loading ?
			<div className={classNames(styles.loading, {
				[styles.hide]: !loading,
			})}>
				<Loading />
			</div>

			: <div style={{ opacity: loading ? 0 : 1 }}>
				<Screen>
					<TextBubble>Woher kennen Sie uns?</TextBubble>

					<AnswersList
						answers={surveyList}
						variant={styles.answersListPps}
						returnType="text"
						onChoose={async (answer) => {
							setSelectedOther(answer.value === "other");

							if (answer.value === "other") {
								return;
							}

							setTimeout(() => setLoading(true), 500);

							setSelectedAnswer(answer.value);

							const { data } = await sendAnswer("pps", answer.text, {
								order_id: orderData.order_id,
							});

							if (data.success) {
								window.location.href = orderData.url;
							}

							sessionStorage.removeItem("order_data");
						}}
						chosen={selectedAnswer}
					/>

					{
						selectedOther && (
							<form className={styles.other} onSubmit={form.handleSubmit}>
								<FormTextarea
									name="message"
									form={form}
									floatingLabel
									placeholder="Der Grund ist..." />

								<ContinueButton loader={loading} type="submit" />
							</form>
						)
					}

					<div className={styles.skipPps}>
						<a href={orderData?.url}>
							keine Angabe
						</a>
					</div>
				</Screen>
			</div>}
		</div>
	);
}
