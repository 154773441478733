import { useEffect } from "react";
import { useDelayLink } from "helpers/hooks";

import ContinueButton, { useContinueButton } from "features/assessment-quiz/Quiz/components/ContinueButton";
import { fireQuizAnalyticsEvent } from "features/assessment-quiz/helpers";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";
import Screen from "features/assessment-quiz/Quiz/components/Screen";

import styles from './index.module.scss';

export default function WithoutAnAppointment ({ next: nextLink }) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();

    
    const {addAnswerHistory} = useQuiz();

    useEffect(() => {
        
        addAnswerHistory('ohne-termin');
    }, []);

    return (
        <Screen className={styles.screen}>

            <div className={styles.text}>
                <p>
                    Kein Problem. Sobald Sie einen Hörtest gemacht haben, schicken Sie
                    uns diesen bitte zu, damit wir Ihre Hörgeräte optimal einstellen und
                    für das Probehören versenden können.
                </p>

                <p>
                    Die Anmeldung für das Probehören können Sie natürlich zunächst
                    abschließen, auch ohne Hörtest.
                </p>

            </div>

            <ContinueButton loader={loading} onClick={() => {
                setLoading(true);
                fireQuizAnalyticsEvent('ohne-termin', '');
                next({ to: nextLink }, () => setLoading(false));
            }} />

        </Screen>
    );
}
