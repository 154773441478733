import config from "../../../config";

import SaleCountdownBox from "../../sale-countdown-box";

import EchoProImage from "assets/img/assessment-quiz/echo-pro.png";
import EchoOneImage from "assets/img/assessment-quiz/echo-one.png";
import EchoProAkkuImage from "assets/img/assessment-quiz/echo-akku.png";
import EchoOneAkkuImage from "assets/img/assessment-quiz/echo-one-akku-ladestation.png";

const {
	echoOneProductID,
	echoProProductID,
	echoOneVariationID,
	echoOneHighEndVariationID,
	echoOneAkkuHighEndVariationID,
	echoProAccumVariationID,
	echoProAccumHighEndVariationID,
	echoProBatteryVariationID,
	echoProBatteryHighEndVariationID,
} = config;

const hearingAidsNew = [
	{
		name: 'Echo Pro',
		id: echoProProductID,
		recommended: true,
		name_html: (<>Echo&nbsp;Pro&nbsp;</>),
		image: EchoProImage,
		akkuImage: EchoProAkkuImage,
		configurations: {
			premium: {
				akku: echoProAccumVariationID,
				batterie: echoProBatteryVariationID,
			},
			highEnd: {
				akku: echoProAccumHighEndVariationID,
				batterie: echoProBatteryHighEndVariationID,
			},
		},
		ownContribution: {
			both: '325-370€',
			one: '175-190€',
		},
		echoProductConfigPrices: {},
		price: '',
		comparisonPrice: {},
		features: [
			"6 Jahre lang kostenlose Fernanpassungen",
			"Kostenlose Reparaturen",
			"30 Tage kostenlos Testen",
			"App-Steuerung",
			"Nahezu unsichtbar",
			"Störlärmunterdrückung",
			"Omnidirektionales Raumhören",
			"Bluetooth & Streamingfähig",
		],
		recommendations: [
			"✓ noch müheloser verstehen in <b>Gruppengesprächen</b>",
			"✓ besseres <b>Richtungshören</b> durch adaptive Mikrofontechnik",
			"✓ satter und natürlicher Klang beim <b>Musikhören</b> durch erweiterten Frequenzbereich",
		],
		saleCountDown: <SaleCountdownBox size="sm" />,
	},
	{
		name: 'Echo One',
		id: echoOneProductID,
		parentId: echoProProductID,
		name_html: (<>Echo&nbsp;One</>),
		image: EchoOneImage,
		akkuImage: EchoOneAkkuImage,
		configurations: {
			premium: {
				batterie: echoOneVariationID,
			},
			highEnd: {
				akku: echoOneAkkuHighEndVariationID,
				batterie: echoOneHighEndVariationID,
			},
		},
		ownContribution: {
			both: 'Nulltarif&nbsp;(25-70€)',
			one: 'Nulltarif&nbsp;(25-40€)',
		},
		echoProductConfigPrices: {},
		price: {},
		comparisonPrice: {},
		features: [
			"6 Jahre lang kostenlose Fernanpassungen",
			"Kostenlose Reparaturen",
			"30 Tage kostenlos Testen",
			"App-Steuerung",
			"Nahezu unsichtbar",
			"Störlärmunterdrückung",
			"Omnidirektionales Raumhören",
		],
		recommendations: [
			"✓ noch müheloser verstehen in <b>Gruppengesprächen</b>",
			"✓ besseres <b>Richtungshören</b> durch adaptive Mikrofontechnik",
			"✓ satter und natürlicher Klang beim <b>Musikhören</b> durch erweiterten Frequenzbereich",
		],
		saleCountDown: <SaleCountdownBox size="sm" />
	},
];

const formatPrice = (price) => {
	return new Intl.NumberFormat("de-DE").format(price); // Adjust 'de-DE' to your locale if needed
};

function calculatePremiumOwnContribution(variation_id, item) {

	const variationExtraPrices = window.assessmentQuizMeta.products[item.id].variations.find(v => v.id === variation_id).variationExtraPrices;

	let technologiestufeExtraPriceAkku = variationExtraPrices.technologiestufeExtraPrice["akku"];
	let technologiestufeExtraPriceBattery = variationExtraPrices.technologiestufeExtraPrice["batterie"];

	let technologiestufeExtraPriceEchoOne = variationExtraPrices.technologiestufeExtraPrice["echo-one"];

	let accumulatorExtraPricePremium = variationExtraPrices.accumulatorExtraPrice["premium"];
	let accumulatorExtraPriceHighEnd = variationExtraPrices.accumulatorExtraPrice["high-end"];

	let accumulatorExtraPriceEchoOneHighEndAkku = variationExtraPrices.accumulatorExtraPrice["echo-one-high-end-akku"];


	return {
		technologiestufeExtraPriceAkku,
		technologiestufeExtraPriceBattery,
		accumulatorExtraPricePremium,
		accumulatorExtraPriceHighEnd,
		technologiestufeExtraPriceEchoOne,
		accumulatorExtraPriceEchoOneHighEndAkku,
	};
}

function calculateHighEndOwnContribution(isEchoPro, isEchoOne, isEchoOneHighEndAkku, isEchoProHighEnd, extraPrices, item, variation_id, qty) {
	let ownContributionOne, ownContributionBoth, ownContributionBothDiscount, ownContributionOneDiscount;

	if (isEchoProHighEnd) {
		ownContributionOne = `${formatPrice(extraPrices.technologiestufeExtraPriceBattery + 25)}-${formatPrice(extraPrices.technologiestufeExtraPriceBattery + 40)}`;
		ownContributionBoth = `${formatPrice((extraPrices.technologiestufeExtraPriceBattery * qty) + 25)}-${formatPrice((extraPrices.technologiestufeExtraPriceBattery * qty) + 70)}`;
	} else if (isEchoOne) {
		ownContributionOne = `${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 25)}-${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 40)}`;
		ownContributionBoth = `${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne * qty + 25)}-${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne * qty + 70)}`;
	} else if (isEchoOneHighEndAkku) {
		ownContributionOneDiscount = `${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 25 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty] - getDiscounts(variation_id, item.id))}-${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 40 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty] - getDiscounts(variation_id, item.id))}`;
		ownContributionBothDiscount = `${formatPrice((extraPrices.technologiestufeExtraPriceEchoOne * qty) + 25 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty] - (getDiscounts(variation_id, item.id) * 2))}-${formatPrice((extraPrices.technologiestufeExtraPriceEchoOne * qty) + 70 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty] - (getDiscounts(variation_id, item.id) * 2))}`;

		ownContributionOne = `${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 25 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty])}-${formatPrice(extraPrices.technologiestufeExtraPriceEchoOne + 40 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty])}`;
		ownContributionBoth = `${formatPrice((extraPrices.technologiestufeExtraPriceEchoOne * qty) + 25 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty])}-${formatPrice((extraPrices.technologiestufeExtraPriceEchoOne * qty) + 70 + extraPrices.accumulatorExtraPriceEchoOneHighEndAkku[qty])}`;
	} else {
		ownContributionOneDiscount = `${formatPrice(extraPrices.technologiestufeExtraPriceAkku + 25 + extraPrices.accumulatorExtraPriceHighEnd[qty] - getDiscounts(variation_id, item.id))}-${formatPrice(extraPrices.technologiestufeExtraPriceAkku + 40 + extraPrices.accumulatorExtraPriceHighEnd[qty] - getDiscounts(variation_id, item.id))}`;
		ownContributionBothDiscount = `${formatPrice((extraPrices.technologiestufeExtraPriceAkku * qty) + 25 + extraPrices.accumulatorExtraPriceHighEnd[qty] - (getDiscounts(variation_id, item.id) * 2))}-${formatPrice((extraPrices.technologiestufeExtraPriceAkku * qty) + 70 + extraPrices.accumulatorExtraPriceHighEnd[qty] - (getDiscounts(variation_id, item.id) * 2))}`;

		ownContributionOne = `${formatPrice(extraPrices.technologiestufeExtraPriceAkku + 25 + extraPrices.accumulatorExtraPriceHighEnd[qty])}-${formatPrice(extraPrices.technologiestufeExtraPriceAkku + 40 + extraPrices.accumulatorExtraPriceHighEnd[qty])}`;
		ownContributionBoth = `${formatPrice((extraPrices.technologiestufeExtraPriceAkku * qty) + 25 + extraPrices.accumulatorExtraPriceHighEnd[qty])}-${formatPrice((extraPrices.technologiestufeExtraPriceAkku * qty) + 70 + extraPrices.accumulatorExtraPriceHighEnd[qty])}`;
	}

	return {
		discounts: {
			one: ownContributionOneDiscount,
			both: ownContributionBothDiscount,
		},
		one: ownContributionOne,
		both: ownContributionBoth,
	};
}

function getDiscounts(variation_id, product_id) {
	return window.assessmentQuizMeta.products[product_id].variations.find(v => v.id === variation_id).discounts;
}

const getVariationExtraPrices = (variation, product) => {
	const { attributes, variationExtraPrices } = variation;

	if (variationExtraPrices.accumulatorExtraPrice && variationExtraPrices.technologiestufeExtraPrice) {
		let accumulatorExtraPrice = variationExtraPrices.accumulatorExtraPrice[attributes.pa_technologiestufe];
		let technologiestufeExtraPrice = variationExtraPrices.technologiestufeExtraPrice[attributes.pa_energiequelle];

		if (product.id === echoOneProductID) {
			technologiestufeExtraPrice = variationExtraPrices.technologiestufeExtraPrice["echo-one"];
			accumulatorExtraPrice = variationExtraPrices.accumulatorExtraPrice["echo-one-high-end-akku"];
		}

		return {
			accumulatorExtraPrice,
			technologiestufeExtraPrice,
		};
	}

	return null;
}

export function calcPrice(variation_id, qty) {
	hearingAidsNew.map((item, key) => {
		const variation = window.assessmentQuizMeta.products[item.id].variations.find(v => v.id === variation_id);

		if (!variation) {
			return;
		}

		const comparisonPrice = variation.comparisonPrice[qty];

		const isEchoPro = variation_id === echoProBatteryVariationID;
		const isEchoProHighEnd = variation_id === echoProBatteryHighEndVariationID;

		const isEchoAkkum = variation_id === echoProAccumVariationID;
		const isEchoOne = variation_id === echoOneVariationID;
		const isEchoOneHighEndAkku = variation_id === echoOneAkkuHighEndVariationID;

		const ownContributionPremium = calculatePremiumOwnContribution(variation_id, item);

		const ownContributionHighEnd = calculateHighEndOwnContribution(isEchoPro, isEchoOne, isEchoOneHighEndAkku, isEchoProHighEnd, ownContributionPremium, item, variation_id, 1);
		const ownContributionHighEndBoth = calculateHighEndOwnContribution(isEchoPro, isEchoOne, isEchoOneHighEndAkku, isEchoProHighEnd, ownContributionPremium, item, variation_id, 2);

		const echoProductConfigPrices = getVariationExtraPrices(variation, item);

		hearingAidsNew[key] = {
			...hearingAidsNew[key],
			comparisonPrice,
			echoProductConfigPrices,
			ownContribution: {
				"discounts": {
					"premium": {
						one: `${!isEchoAkkum ? `Nulltarif (25-40€)` : `${ownContributionPremium.accumulatorExtraPricePremium[1] + 25 - getDiscounts(variation_id, item.id)}-${ownContributionPremium.accumulatorExtraPricePremium[1] + 40 - getDiscounts(variation_id, item.id)}€`}`,
						both: `${!isEchoAkkum ? `Nulltarif* (25-70€)` : `${ownContributionPremium.accumulatorExtraPricePremium[2] + 25 - (getDiscounts(variation_id, item.id) * 2)}-${ownContributionPremium.accumulatorExtraPricePremium[2] + 70 - (getDiscounts(variation_id, item.id) * 2)}€`}`,
					},
					"highEnd": {
						one: `${ownContributionHighEnd.discounts.one}€`,
						both: `${ownContributionHighEndBoth.discounts.both}€`,
					},
				},
				"premium": {
					one: `${!isEchoAkkum ? `Nulltarif (25-40€)` : `${ownContributionPremium.accumulatorExtraPricePremium[1] + 25}-${ownContributionPremium.accumulatorExtraPricePremium[1] + 40}€`}`,
					both: `${!isEchoAkkum ? `Nulltarif* (25-70€)` : `${ownContributionPremium.accumulatorExtraPricePremium[2] + 25}-${ownContributionPremium.accumulatorExtraPricePremium[2] + 70}€`}`,
				},
				"highEnd": {
					one: `${ownContributionHighEnd.one}€`,
					both: `${ownContributionHighEndBoth.both}€`,
				},
			},
		};

		return key;
	});

}

export default hearingAidsNew;

export function getRecommendedProduct(answers) {
	const EchoOne = hearingAidsNew.find(product => product.configurations["premium"]["batterie"] === echoOneVariationID);
	const EchoProAccum = hearingAidsNew.find(product => product.configurations["premium"]["akku"] === echoProAccumVariationID);

	if (answers["wichtig-bei-neuen-hg"] === "Unauffälliges Design") {
		return EchoOne;
	}

	return EchoProAccum;
}
