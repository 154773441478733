import config from "config";

const analytics = {

  isLoggingEnabled: function () {
    return false;
  },

  fireEvent: function (event, args) {

    if (process.env.NODE_ENV === 'production') {

      const {gtag} = window;

      if (gtag && typeof gtag === 'function') {

        gtag('event', event, {
          send_to: config.GA4TrackingID,
          ...args
        })

      } else if (this.isLoggingEnabled()) {
        console.log('Real even is not triggered. Google Analytics library is missing.');
      }
    }

    if (this.isLoggingEnabled()) {
      console.log('Google Analytics 4 Event', {
        event: event,
        args: args
      });
    }
  },

};

export default analytics;
