import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Rating from 'components/Rating';

import { useDelayLink } from 'helpers/hooks';

import ChargerImage from 'assets/img/assessment-quiz/modals/hearingaids-charger.png';
import NoiseSuppressionImage from 'assets/img/assessment-quiz/modals/hearingaids-noisesuppression.png';
import StreamingImage from 'assets/img/assessment-quiz/modals/hearingaids-streaming.png';
import VoiceBoostImage from 'assets/img/assessment-quiz/modals/hearingaids-voiceboost.png';

import DIOPDesignImage from 'assets/img/assessment-quiz/modals/hearingaids-diop-design.png';
import DIOPRecommendationImage from 'assets/img/assessment-quiz/modals/hearingaids-diop-recommendation.png';

import AppGif from 'assets/img/assessment-quiz/modals/hearingaids-app.gif';
import InvisibleGif from 'assets/img/assessment-quiz/modals/hearingaids-invisible.gif';

import HearingAidsImage from 'assets/img/assessment-quiz/modals/hearingaids-hearingaids.png';

import EchoOneImage from 'assets/img/assessment-quiz/modals/hearingaids-echo-one.png';
import EchoProImage from 'assets/img/assessment-quiz/modals/hearingaids-echo-pro.png';

import styles from './index.module.scss';

const features = [
    {
        id: 'invisible',
        title: 'Nahezu unsichtbarer Tragekomfort',
        description: 'Die Echo Hörgeräte sind so klein, dass sie von Mitmenschen kaum gesehen werden.',
        badges: ['Echo One', 'Echo Pro'],
        image: InvisibleGif,
    },
    {
        id: 'voiceBoost',
        title: 'Mühelose Unterhaltungen dank dynamischer Sprachanhebung',
        description: 'Kann Sprache von anderen Geräuschen unterscheiden und Sprache hervorheben.',
        badges: ['Echo One', 'Echo Pro'],
        image: VoiceBoostImage,
    },
    {
        id: 'app',
        title: 'Bequeme Steuerung per App',
        description: 'Ändern Sie die Lautstärke oder wechseln Sie die Hörprogramme mit der kostenlosen Smartphone-App.',
        badges: ['Echo One', 'Echo Pro'],
        image: AppGif,
    },
    {
        id: 'noiseSuppression',
        title: 'Störlärmunterdrückung reduziert ableckenden Lärm',
        description: 'Für entspannte und mühelose Gespräche in allen Lebenslagen.',
        badges: ['Echo One', 'Echo Pro'],
        image: NoiseSuppressionImage,
    },
    {
        id: 'charger',
        title: 'Optional als Akku-Version: 3 Std aufladen, 24 Std hören',
        description: 'Sie sparen rund 200€ Batteriekosten pro Gerät über 6 Jahre (mehr als der Akku-Aufpreis).',
        badges: ['Echo Pro'],
        image: ChargerImage,
    },
    {
        id: 'streaming',
        title: 'Streaming: Fernsehen, Musik oder Telefonieren per Bluetooth direkt im Hörgerät',
        description: 'Nutzen Sie Ihre Hörgeräte als Kopfhörer mit großartigem Klang.',
        badges: ['Echo Pro'],
        image: StreamingImage,
    },
];

let unsureLinks = [
    {
        link: 'geraetewahl-beide',
        image: HearingAidsImage,
        title: 'Beide Hörgeräte testen',
        description: 'Echo One und Echo Pro probehören >',
        hideOnSameSite: true,
    },
];

export default function HearingAidsModal () {
    const next = useDelayLink(),
        location = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedLink, setSelectedLink] = useState(false);

    function openModal (e) {
        e.preventDefault();
        setIsOpen(true);
    }

    function closeModal () {
        setIsOpen(false);
    }

    useEffect(() => {
        if (!selectedLink) {
            return;
        }

        if (location.pathname === `/${selectedLink}`) {
            setIsOpen(false);
            return;
        }

        next({ to: `/${selectedLink}` });
    }, [selectedLink, location.pathname]);

    unsureLinks = unsureLinks.filter((item) => !(item.hideOnSameSite && location.pathname.includes(item.link)));

    return (
        <>
            <div className={styles.link}>
                <a href="#" onClick={openModal}>mehr Infos zu den Hörgeräten</a>
            </div>

            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                title='Die Echo Hörgeräte'
                wrapperClassName={styles.wrapper}
            >
                <div className={styles.container}>

                    <div className={classNames(styles.item, styles.hearingAids)}>
                        <div className={styles.name}>
                            <img className={styles.echoOneImage} src={EchoOneImage} alt="" />
                            <h4>Echo One</h4>
                        </div>

                        <div className={styles.name}>
                            <img className={styles.echoProImage} src={EchoProImage} alt="" />
                            <h4>Echo Pro &<br />Echo Pro Akku</h4>
                        </div>

                        <div className={classNames(styles.row, styles.rowBg, styles.rowFull)}>
                            Hochleistungschip für herausragendes Sprachverstehen
                        </div>

                        <div className={classNames(styles.row, styles.rowBg, styles.rowFull)}>
                            nahezu unsichtbar für Mitmenschen
                        </div>

                        <div className={classNames(styles.row, styles.rowBg, styles.rowFull)}>
                            Steuerung per App
                        </div>

                        <div className={styles.row}>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 25.35H34V22.35H14V25.35ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7333 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05833 11.65 9.875 9.85C11.6917 8.05 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2667 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.95 36.3083 38.15 38.125C36.35 39.9417 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44ZM24 41C28.7333 41 32.75 39.3417 36.05 36.025C39.35 32.7083 41 28.7 41 24C41 19.2667 39.35 15.25 36.05 11.95C32.75 8.65 28.7333 7 24 7C19.3 7 15.2917 8.65 11.975 11.95C8.65833 15.25 7 19.2667 7 24C7 28.7 8.65833 32.7083 11.975 36.025C15.2917 39.3417 19.3 41 24 41Z" fill="black" fillOpacity="0.13" />
                            </svg>
                        </div>

                        <div className={classNames(styles.row, styles.rowBg)}>
                            Streaming (Musik, TV & Telefonieren über das Hörgerät)
                        </div>

                        <div className={styles.row}>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 25.35H34V22.35H14V25.35ZM24 44C21.2667 44 18.6833 43.475 16.25 42.425C13.8167 41.375 11.6917 39.9417 9.875 38.125C8.05833 36.3083 6.625 34.1833 5.575 31.75C4.525 29.3167 4 26.7333 4 24C4 21.2333 4.525 18.6333 5.575 16.2C6.625 13.7667 8.05833 11.65 9.875 9.85C11.6917 8.05 13.8167 6.625 16.25 5.575C18.6833 4.525 21.2667 4 24 4C26.7667 4 29.3667 4.525 31.8 5.575C34.2333 6.625 36.35 8.05 38.15 9.85C39.95 11.65 41.375 13.7667 42.425 16.2C43.475 18.6333 44 21.2333 44 24C44 26.7333 43.475 29.3167 42.425 31.75C41.375 34.1833 39.95 36.3083 38.15 38.125C36.35 39.9417 34.2333 41.375 31.8 42.425C29.3667 43.475 26.7667 44 24 44ZM24 41C28.7333 41 32.75 39.3417 36.05 36.025C39.35 32.7083 41 28.7 41 24C41 19.2667 39.35 15.25 36.05 11.95C32.75 8.65 28.7333 7 24 7C19.3 7 15.2917 8.65 11.975 11.95C8.65833 15.25 7 19.2667 7 24C7 28.7 8.65833 32.7083 11.975 36.025C15.2917 39.3417 19.3 41 24 41Z" fill="black" fillOpacity="0.13" />
                            </svg>
                        </div>

                        <div className={classNames(styles.row, styles.rowBg)}>
                            Optional als Akku-Version
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button variant='secondary' onClick={() => closeModal()}>Zurück zur Geräteauswahl</Button>
                    </div>

                    <div className={styles.item}>
                        <header className={styles.header}>
                            <h2>Voller Technologien, die Ihr Leben wieder bunter machen.</h2>
                        </header>

                        <div className={styles.features}>
                            {features.map((feature) => (
                                <div className={classNames(styles.feature, `feature-${feature.id}`)} key={feature.title}>
                                    <div className={styles.thumbnail}>
                                        <img className={styles.image} src={feature.image} alt={feature.name} />
                                        <div className={styles.badges}>
                                            {feature.badges.map((badge) =>
                                                <div className={styles.badge} key={badge}>✓ {badge}</div>)}
                                        </div>
                                    </div>

                                    <div className={styles.content}>
                                        <h5>{feature.title}</h5>
                                        <p>{feature.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button variant='secondary' onClick={() => closeModal()}>Zurück zur Geräteauswahl</Button>
                    </div>

                    <div className={classNames(styles.item, styles.eligible)}>
                        <h2>Beide Echo Hörgeräte sind perfekt geeignet für:</h2>

                        <ul>
                            <li>✓ Ihren aktiven Lebensstil</li>
                            <li>✓ anspruchsvolle Hörsituationen mit lauten Umgebungsgeräuschen</li>
                            <li>✓ Gruppengespräche (z.B. Familienfeier oder Meetings)</li>
                            <li>✓ Fernsehen & Telefonieren (das Echo Pro kann den Ton sogar direkt per Streaming übertragen)</li>
                        </ul>
                    </div>

                    {unsureLinks.length > 0 && (
                        <div className={styles.item}>
                            <header className={styles.header}>
                                <h2>Unsicher, welches Hörgerät das richtige für Sie ist?</h2>
                            </header>

                            <div className={classNames(styles.unsure, {
                                [styles.twoRows]: unsureLinks.length >= 2,
                            })}>
                                {unsureLinks.map((item) => (
                                    <div
                                        key={item.link}
                                        className={classNames(styles.link, {
                                            [styles.selected]: selectedLink === item.link,
                                        })}
                                        onClick={() => setSelectedLink(item.link)}
                                    >
                                        <img src={item.image} alt="" />

                                        <div className={styles.text}>
                                            <h5>{item.title}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className={styles.item}>
                        <header className={styles.header}>
                            <h2>Tausende Kunden lieben die Echo Hörgeräte.</h2>
                        </header>

                        <Rating />

                        <div className={styles.seals}>
                            <a href='https://www.diqp.eu/testergebnisse/top-design/hearing-innovations-ag-dein-echo-de-echo-pro/' target='_blank' rel='noreferrer'>
                                <img src={DIOPDesignImage} alt='DIOP Top Design' />
                            </a>
                            <a href='https://www.diqp.eu/testergebnisse/kundenempfehlung/hearing-innovations-ag-dein-echo-de/' target='_blank' rel='noreferrer'>
                                <img src={DIOPRecommendationImage} alt='DIOP Kundenempfehlung' />
                            </a>
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button variant='secondary' onClick={() => closeModal()}>Zurück zur Geräteauswahl</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
