import Button from "components/Button";
import ArrowRight from "components/Icon/ArrowRight";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import { useDelayLink } from "helpers/hooks";

export default function ExitUnder18 () {
    const next = useDelayLink(),
        {resetQuiz} = useQuiz();

    return (
        <Screen>

            <p>Leider können wir keine Versorgungen für unter 18-jährige durchführen.</p>

            <Button
                style={{ width: '100%', marginBottom: 24 }}
                onClick={() => {
                    resetQuiz();
                    next({ to: '@/' });
                }}
            >
                Zurück zur Website <ArrowRight size={12} />
            </Button>

            <p>Füllen Sie den Fragebogen für <b>Ihre Eltern oder Großeltern</b> aus? Antworten Sie bitte aus deren Perspektive.</p>

            <Button 
                style={{ width: '100%', marginBottom: 24 }}
                variant='secondary'
                onClick={() => {
                    resetQuiz();
                    next({ to: '/' });
                }}
            >
                Fragebogen neu starten
            </Button>
            
        </Screen>
    );
}
