import { useEffect } from "react";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import { useDelayLink } from "helpers/hooks";

import RemoteAdjustmentImage from 'assets/img/assessment-quiz/info-screens/remote-adjustment.jpg';

import ContinueButton, { useContinueButton } from "features/assessment-quiz/Quiz/components/ContinueButton";
import { fireQuizAnalyticsEvent } from "features/assessment-quiz/helpers";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";

import styles from './index.module.scss';

export default function RemoteAdjustment ({ next: nextLink }) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();

    
    const {addAnswerHistory} = useQuiz();

    useEffect(() => {
        
        addAnswerHistory('info-fernanpassung');
    }, []);

    return (
        <Screen className={styles.screen}>

            <img className={styles.image} src={RemoteAdjustmentImage} />

            <p><b>Sie sind in besten Händen:</b> Unsere Akustiker begleiten Sie in jedem Schritt.</p>

            <ul className={styles.list}>
                <li>Feinanpassungen bequem von überall</li>
                <li>keine Termine, einfach anrufen</li>
                <li>neue Einstellungen direkt im Alltag testen (nicht in stiller Akustiker-Kabine)</li>
            </ul>

            <ContinueButton loader={loading} onClick={() => {
                setLoading(true);
                fireQuizAnalyticsEvent('info-fernanpassung', '');;
                next({ to: nextLink }, () => setLoading(false));
            }} />

        </Screen>
    );
}
