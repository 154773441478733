import { useEffect } from "react";
import classNames from "classnames";

import ContinueButton, { useContinueButton } from "features/assessment-quiz/Quiz/components/ContinueButton";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import { fireQuizAnalyticsEvent } from "features/assessment-quiz/helpers";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";

import { useDelayLink } from "helpers/hooks";

import EchoLogoWhite from 'assets/img/assessment-quiz/echo-logo-white.png';

import styles from './index.module.scss';

export default function CustomerSatisfaction ({ next: nextLink }) {
    const {addAnswerHistory} = useQuiz();

    useEffect(() => {
        
        addAnswerHistory('info-kundenzufriedenheit');
    }, []);

    return (
        <Screen className={styles.screen}>

            <div className={styles.customerSatisfaction}>
                <div>
                    <h3>Geprüfte Kundenumfragen bestätigen: <b>Echo-Kunden sind deutlich zufriedener</b> als beim Akustiker vor Ort.</h3>
                    <small>Quellen: 68% aus Stiftung Warentest Umfrage 11/2019 | 96% aus geprüften Trusted Shops Kundenbewertungen (Stand 12/2022)</small>
                    <Continue className='buttonDesktop' nextLink={nextLink} />
                </div>

                <div className={styles.bars}>
                    <div className={styles.bar}>
                        <span>68%</span>
                        <strong>Andere</strong>
                    </div>
                    <div className={classNames(styles.bar, styles.echo)}>
                        <span>96%</span>
                        <strong>
                            <img src={EchoLogoWhite} alt='Echo Logo' />
                        </strong>
                    </div>
                </div>
            </div>

            <Continue className='buttonMobile' nextLink={nextLink} />

        </Screen>
    );
}

function Continue ({ nextLink, ...rest }) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();

    return (
        <ContinueButton loader={loading} {...rest} onClick={() => {
            setLoading(true);
            fireQuizAnalyticsEvent('info-kundenzufriedenheit', '');
            next({ to: nextLink }, () => setLoading(false));
        }} />
    )
}
