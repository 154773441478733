import classNames from "classnames";

import LinkModal from "components/LinkModal";
import { InfoIcon } from "components/Icon";
import ContinueButton from "../ContinueButton";

import { useQuiz } from "features/assessment-quiz/Page/QuizContext";
import config from "../../../../../config";

import { ReactComponent as CheckmarkIcon } from "assets/icons/checkmark.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as WhiteCheckmarkIcon } from "assets/icons/white-checkmark.svg";
import styles from "./index.module.scss";

const {
	echoProProductID,
	echoOneProductID,
} = config;

export default function ProductCardNew({
	next,
	product,
	className = null,
	onClick = null,
	onTechnologyLevelClick,
	onEnergySourceClick,
	technologyLevel,
	energySource,
	loading,
	setLoading,
}) {
	const { getChosenAnswer, setAdditionalAnswer, setAnswer } = useQuiz();

	const ears = getChosenAnswer("welche-ohren") === "für beide Ohren" ? "both" : "one";
	const isEchoOne = product.id === echoOneProductID;
	const isEchoPro = product.id === echoProProductID;

	const handleLinkModalClick = (event) => {
		event.stopPropagation();
	};

	// const discounts = getDiscounts(product.parentId, product.id, qty);

	return (
		<div className={classNames(styles.productCard, className, {
			[styles.echoPro]: isEchoPro,
		})}>
			<div className={classNames(styles.productInfo, {
				[styles.recommended]: product.recommended,
			})}

			     onClick={() => onClick(product)}>

				<div className={styles.productData}>

					<div className={styles.image}>
						<img src={product.image} alt={product.name} />
					</div>

					<h4 className={styles.name}>{product.name_html}</h4>

					<div className={styles.rating}>
						<etrusted-widget data-etrusted-widget-id="wdg-cb6a1547-a546-4faf-8b1d-4c3a135ce121"
						                 data-sku={product.id}></etrusted-widget>
					</div>

					<ul className={styles.features}>
						{product.features.map((feature) => (
							<li key={feature}><CheckmarkIcon />{feature}</li>
						))}
					</ul>

					<h5 className={styles.technologyLevelTitle}>Machen Sie Ihr Hörgeräte noch <br /> Leistungsstärker:
					</h5>

					<div
						className={classNames(styles.option, {
							[styles.selected]: technologyLevel === "highEnd",
						})}
						onClick={(e) => {
							onTechnologyLevelClick(technologyLevel === "highEnd" ? "premium" : "highEnd");
						}}
					>
						<div>
							<h6>High-End Technologiestufe</h6>
							<span>{product.echoProductConfigPrices?.technologiestufeExtraPrice ?? 0}€ pro Ohr</span>
						</div>

						<i>{technologyLevel === "highEnd" ? <WhiteCheckmarkIcon /> : <PlusIcon />}</i>
					</div>

					<div className={styles.recommendation}>
						<p className={styles.title}>Vorteile der High-End-Klasse:</p>

						<ul>
							{product.recommendations.map((recommendation) => (
								<li dangerouslySetInnerHTML={{ __html: recommendation }} key={recommendation} />
							))}
						</ul>
					</div>

					{isEchoOne && technologyLevel === "premium" &&
						<p className={styles.disabledText}>nur in der High-End Version erhältlich</p>
					}

					<div
						className={classNames(styles.option, styles.akku, {
							[styles.selected]: energySource === "akku",
							[styles.disabled]: isEchoOne && technologyLevel === "premium",
						})}
						onClick={(e) => {
							onEnergySourceClick(energySource === "akku" ? "batterie" : "akku");
						}}
					>

						<div>
							<img className={classNames(styles.akkuImage, {
								[styles.akkuDisabled]: isEchoOne && technologyLevel === "premium",
							})}
							     src={product.akkuImage}
							     alt={product.name}
							/>
							<div>
								<h6>Akku</h6>
								<span>+ {product.echoProductConfigPrices.accumulatorExtraPrice?.[1] ?? 0}€ pro Ohr</span>
							</div>
						</div>

						<i>{energySource === "akku" ? <WhiteCheckmarkIcon /> : <PlusIcon />}</i>
					</div>

					{isEchoPro &&
						<p className={styles.optionSubText}>
							Die Akku-Variante ist in 3 Std aufgeladen und hält 24 Std.
						</p>
					}

					{(isEchoOne && technologyLevel === "highEnd") &&
						<p className={classNames(styles.optionSubText, styles.echoOneOptionSubText)}>
							Die Akku-Variante hält 28 Std. und kommt mit einem mobilen Aufladegerät für bis zu 3 weitere
							Ladungen ohne Steckdose einher
						</p>
					}

					<div className={styles.ownContribution}>
						Eigenanteil bei Kauf:&nbsp;
						<div className={styles.price}>
							<b dangerouslySetInnerHTML={{ __html: product.ownContribution?.[technologyLevel]?.[ears] ?? 0 }} />

							<LinkModal linkClassName={styles.infoIcon} linkText={<InfoIcon />}
							           modalTitle="Eigenanteil bei Kauf" onClick={handleLinkModalClick}>
								<p>Der Eigenanteil ist nur dann relevant, wenn Sie sich nach der Testphase aktiv für die
									Hörgeräte entscheiden. Die Testphase ist zu 100% kostenlos und unverbindlich.</p>
								<p>Nulltarif bedeutet, dass Mitglieder der gesetzlichen Krankenkassen lediglich den
									gesetzlichen Eigenanteil (10€ pro Gerät) sowie eine Verwaltungspauschale (0€ - 50€)
									zahlen. Der genaue Betrag hängt von Ihrer Krankenkasse ab.</p>
								<p>Voraussetzung ist immer ein Leistungsanspruch ggü. Ihrer Krankenkasse sowie eine
									ohrenärztliche Verordnung.</p>
							</LinkModal>
						</div>
					</div>

					<div className={styles.comparisonPrice}>
						⌀ Eigenanteil beim Akustiker:&nbsp;
						<div className={styles.price}>
							<b dangerouslySetInnerHTML={{ __html: typeof product.comparisonPrice === 'object' ? '0' : (product.comparisonPrice ?? '0') }} />€

							<LinkModal
								modalTitle="Vergleichpreis beim Akustiker"
								linkClassName={styles.infoIcon}
								linkText={<InfoIcon />}
								onClick={handleLinkModalClick}
							>
								<p>
									Der Vergleichspreis ist der Durchschnittspreis aus einer Umfrage von
									Hörakustik-Filialen in Deutschland (n=38).
									Wir können die Hörgeräte günstiger anbieten, da wir uns Tausende Filialen
									deutschlandweit sparen.&nbsp;
									<a target="_blank" rel="noreferrer" href="/vergleichspreis">Mehr Infos zur
										Datenerhebung</a>
								</p>
							</LinkModal>
						</div>
					</div>
				</div>

				<ContinueButton disabled={!product} loader={loading} className={classNames(styles.continueButton, {
					[styles.recommended]: product.recommended,
				})} onClick={() => {
					setLoading(true);
					setAdditionalAnswer("geraetename", product.name);
					setAnswer("geraetewahl", product.configurations[technologyLevel][energySource]);

					next();
				}} />
			</div>
		</div>
	);
}
