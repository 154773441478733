import { createContext, useState, useMemo, useContext, useEffect } from "react";
import { pickBy } from "lodash";

import { fireQuizAnalyticsEvent, isOrderProcess } from "features/assessment-quiz/helpers";

const { healthInsurances } = window.assessmentQuizMeta;

const healthInsurancesIndex = {};
healthInsurances.forEach(v => healthInsurancesIndex[v.name] = v);

let dataStorageKey = isOrderProcess() ? 'echo-hearing-aids-order-process' : 'echo-assessment-quiz',
    initialData = localStorage.getItem(dataStorageKey);

const defaultData = {
    answers: {},
    answersHistory: [],
    additionalAnswers: {},
};

initialData = initialData ? Object.assign({}, {...defaultData}, JSON.parse(initialData)) : {...defaultData};

const Context = createContext({});

export default function QuizContext ({ children }) {
    const [data, setData] = useState(initialData);
    const value = useMemo(() => ({ data, setData }), [data, setData]);

    useEffect(() => {
        localStorage.setItem(dataStorageKey, JSON.stringify(data));
    }, [data]);

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

export function useQuiz () {
    const { data, setData } = useContext(Context);

    function setAnswer (key, value, cb = null) {

        let answersHistory = data.answersHistory, answers = data.answers;

        let currentIndex = -1,
            answersKey = key;

        if (typeof key === 'object') {

            let lowestIndex = Infinity;

            for (const _key of Object.keys(key)) {
                const currentIndex = answersHistory.indexOf(_key);

                if (currentIndex !== -1) {
                    lowestIndex = Math.min(lowestIndex, currentIndex);
                }

                answersKey = _key;
            }

            currentIndex = lowestIndex;
        } else {
            currentIndex = answersHistory.indexOf(answersKey);
        }

        if (currentIndex !== -1) {
            answersHistory = answersHistory.slice(0, currentIndex);
            answers = pickBy(answers, (value, key) => answersHistory.includes(key));
        }

        const newAnswers = typeof key === 'object' ? key : { [key]: value };

        if (typeof key === 'object') {
            answersHistory = answersHistory.concat(Object.keys(key));
        } else {
            answersHistory.push(answersKey);
        }

        setData((data) => {
            const _data = {
                ...data,
                answers: {
                    ...answers,
                    ...newAnswers
                },
                answersHistory: [
                    ...answersHistory,
                ],
            };

            if (cb) {
                cb(_data.answers);
            }

            return _data;
        });

        fireQuizAnalyticsEvent(key, value);
    }

    function addAnswerHistory (item) {
        let answersHistory = data.answersHistory;

        const currentIndex = answersHistory.indexOf(item);

        if (currentIndex !== -1) {
            answersHistory = answersHistory.slice(0, currentIndex);
        }

        setData((data) => ({
            ...data,
            answersHistory: [
                ...answersHistory,
                item,
            ],
        }));
    }

    function getChosenAnswer (key, defaultVal = null) {
        return data.answers[key] !== undefined ? data.answers[key] : defaultVal;
    }

    function setAdditionalAnswer (key, value) {
        setData((data) => ({
            ...data,
            additionalAnswers: {
                ...data.additionalAnswers,
                [key]: value,
            }
        }))
    }

    function getAdditionalAnswer (key, defaultVal = null) {
        return data.additionalAnswers[key] !== undefined ? data.additionalAnswers[key] : defaultVal;
    }

    function resetQuiz () {
        setData({
            answers: {},
            answersHistory: [],
            additionalAnswers: {},
        });
    }

    return {
        data,
        setData,
        answers: data.answers,
        setAnswer,
        setAdditionalAnswer,
        getAdditionalAnswer,
        getChosenAnswer,
        addAnswerHistory,
        resetQuiz,
    }
}
