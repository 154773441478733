import {  useState } from "react";

import AnswersList from "features/assessment-quiz/Quiz/components/AnswersList";
import Screen from "features/assessment-quiz/Quiz/components/Screen";
import TextBubble from "features/assessment-quiz/Quiz/components/TextBubble";
import { useDelayLink } from "helpers/hooks";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";

import storeLocations from 'data/storeLocations.json';
import { calcDistance } from "helpers/geo";
import Button from "components/Button";

export default function LocationFinderResults ({ next: nextLink }) {
    const next = useDelayLink();
    const {getChosenAnswer, setAnswer} = useQuiz();

    const [showMore, setShowMore] = useState(false);
    const address = getChosenAnswer('adresse');

    if (!address) {
        return (
            <div>
                <h3>Es ist ein Fehler aufgetreten.</h3>

                <Button variant='secondary' href='/'>Fragebogen neu starten</Button>
            </div>
        );
    }

    const distances = storeLocations.map(({location_lat, location_lng}, index) => ({
        storeIndex: index,
        distance: calcDistance(address, {lat: location_lat, lng: location_lng}),
    }));

    const stores = distances.sort((a, b) => a.distance - b.distance).slice(0, showMore ? 8 : 3)
        .map(({storeIndex, distance}) => {
            const store = storeLocations[storeIndex];

            return {
                text: `Fachgeschäft in ${store.location_zip} ${store.location_city}`,
                distance: `${Math.round(distance)} km von Ihnen entfernt`,
                store,
            }
        });

    return (
        <Screen>

            <TextBubble>Entscheiden Sie sich nach der Testphase für die Hörgeräte, ist ein Termin im Fachgeschäft nötig zur Abrechnung mit der Krankenkasse.</TextBubble>

            <p>
                <b>Wo würden Sie den Termin machen wollen, sollten Sie sich nach der Testphase für die Hörgeräte entscheiden?</b>
            </p>

            <p>(Für das Probetragen ist noch <b><u>kein</u></b> Besuch vor Ort nötig.)</p>

            <AnswersList
                variant='rows small'
                answers={stores.map(({text, distance, store}) => ({
                    text: (
                        <>
                            <span style={{display: 'block', marginBottom: '12px'}}>{text}</span>
                            <b>{distance}</b>
                        </>
                    ),
                    value: `${store.location_name}, ${store.location_street_and_number}, ${store.location_zip} ${store.location_city}`,
                    store: store.store,
                }))}
                onChoose={(answer) => {
                    setAnswer('besuch-im-fachgeschaeft', answer);
                    next({ to: nextLink });
                }}
                chosen={getChosenAnswer('besuch-im-fachgeschaeft')}
            />
            
            {!showMore && <a className='info-link' onClick={() => setShowMore(true)}>mehr Standorte laden</a>}

        </Screen>
    );
}
