import { useEffect } from "react";

import Screen from "features/assessment-quiz/Quiz/components/Screen";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";

import { useDelayLink } from "helpers/hooks";
import analytics from "helpers/analytics";

import styles from './index.module.scss';

export default function Eligible ({next: nextLink}) {
    const next = useDelayLink(),
        {addAnswerHistory} = useQuiz();

    useEffect(() => {
        analytics.fireEvent('assessment2_qualified');

        addAnswerHistory('info-probehoeren-moeglich');
        next({ to: nextLink, delay: 7000 });
    }, []);

    return (
        <Screen className={styles.screen}>

            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M40.0001 76.6657C49.7249 76.6657 59.0497 72.8032 65.9281 65.9281C72.8031 59.0499 76.6657 49.7249 76.6657 40.0001C76.6657 30.2753 72.8032 20.9505 65.9281 14.0721C59.0499 7.19703 49.7249 3.33447 40.0001 3.33447C30.2753 3.33447 20.9505 7.19695 14.0721 14.0721C7.19703 20.9502 3.33447 30.2753 3.33447 40.0001C3.33447 49.7249 7.19695 59.0497 14.0721 65.9281C20.9502 72.8031 30.2753 76.6657 40.0001 76.6657ZM20.9657 40.9657C22.2657 39.675 24.3657 39.675 25.6657 40.9657L33.3344 48.6344L52.6344 29.3344C53.9562 28.2 55.9282 28.2782 57.1594 29.5094C58.3906 30.7407 58.4656 32.7094 57.3344 34.0344L35.6688 55.7H35.6656C34.3656 56.9937 32.2656 56.9937 30.9656 55.7L20.9656 45.7C20.3344 45.075 19.9812 44.2218 19.9812 43.3344C19.9812 42.4437 20.3344 41.5937 20.9656 40.9656L20.9657 40.9657Z" fill="#5CC84A"/>
            </svg>


            <h4>Probetragen ist für Sie möglich!</h4>
            <p>Nur noch wenige Schritte, um sich für das kostenlose Probetragen anzumelden.</p>

            <div className={styles.progress}>
                <div className={styles.bar} />
            </div>
            <p>Bitte warten...</p>
        </Screen>
    )
}
