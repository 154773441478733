import { useFormik } from "formik";
import * as Yup from "yup";

import FormInput from "components/FormInput";

import { useDelayLink } from "helpers/hooks";

import ContinueButton, { useContinueButton } from "features/assessment-quiz/Quiz/components/ContinueButton";
import TextBubble from "features/assessment-quiz/Quiz/components/TextBubble";
import { useQuiz } from "features/assessment-quiz/Page/QuizContext";

import styles from './index.module.scss';

const validationSchema = Yup.object().shape({
    phone: Yup.string().required('Bitte geben Sie Ihre Telefonnummer ein'),
    email: Yup.string().email('Bitte geben Sie Ihre E-Mail-Adresse ein').required('Bitte geben Sie Ihre E-Mail-Adresse ein'),
});

export default function ContactDetails ({ next: nextLink }) {
    const next = useDelayLink(),
        {loading, setLoading} = useContinueButton();
    const {getChosenAnswer, setAnswer} = useQuiz();

    const contactDetails = getChosenAnswer('kontaktdaten') ?? { email: '', phone: '' };
    const form = useFormik({
        initialValues: contactDetails,
        validationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (values) => {
            setLoading(true);
            setAnswer('kontaktdaten', values);
            next({ to: nextLink }, () => setLoading(false));
        },
    });

    return (
        <div>

            <TextBubble>Mit Ihren Kontaktdaten können wir Ihre Registrierung für das kostenlose Probehören bestätigen.</TextBubble>

            <form onSubmit={form.handleSubmit}>
                <FormInput
                    name='phone'
                    placeholder='Telefonnummer'
                    form={form}
                    floatingLabel
                />

                <FormInput
                    name='email'
                    placeholder='E-Mail-Adresse'
                    form={form}
                    floatingLabel
                />

                <ContinueButton loader={loading} type='submit' />
            </form>

            <div className={styles.privacyNotice}>
                <h5>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.8125 19.25C4.44792 19.25 4.13802 19.1224 3.88281 18.8672C3.6276 18.612 3.5 18.3021 3.5 17.9375V8.44375C3.5 8.07917 3.6276 7.76927 3.88281 7.51406C4.13802 7.25885 4.44792 7.13125 4.8125 7.13125H6.34375V5.03125C6.34375 3.87917 6.74844 2.89844 7.55781 2.08906C8.36719 1.27969 9.34792 0.875 10.5 0.875C11.6521 0.875 12.6328 1.27969 13.4422 2.08906C14.2516 2.89844 14.6562 3.87917 14.6562 5.03125V7.13125H16.1875C16.5521 7.13125 16.862 7.25885 17.1172 7.51406C17.3724 7.76927 17.5 8.07917 17.5 8.44375V17.9375C17.5 18.3021 17.3724 18.612 17.1172 18.8672C16.862 19.1224 16.5521 19.25 16.1875 19.25H4.8125ZM10.5 14.875C10.9667 14.875 11.3641 14.7146 11.6922 14.3938C12.0203 14.0729 12.1844 13.6865 12.1844 13.2344C12.1844 12.7969 12.0203 12.3995 11.6922 12.0422C11.3641 11.6849 10.9667 11.5062 10.5 11.5062C10.0333 11.5062 9.63594 11.6849 9.30781 12.0422C8.97969 12.3995 8.81562 12.7969 8.81562 13.2344C8.81562 13.6865 8.97969 14.0729 9.30781 14.3938C9.63594 14.7146 10.0333 14.875 10.5 14.875ZM7.65625 7.13125H13.3438V5.03125C13.3438 4.24375 13.0667 3.57292 12.5125 3.01875C11.9583 2.46458 11.2875 2.1875 10.5 2.1875C9.7125 2.1875 9.04167 2.46458 8.4875 3.01875C7.93333 3.57292 7.65625 4.24375 7.65625 5.03125V7.13125Z" fill="#5CC84A"/>
                    </svg>
                    Geprüfte Datensicherheit
                </h5>
                <p>Ihre Daten werden verschlüsselt übertragen. Es gelten unsere <a href="/datenschutz" target='_blank'>Datenschutzhinweise</a> und <a href="/agb" target='_blank'>AGB</a>.</p>
            </div>
        </div>
    );
}
