import React from 'react';

import classNames from 'classnames';

import Textarea from "components/Textarea";

import styles from './index.module.scss';
import {abEchoClass, sanitizeTextForClassName} from "../../helpers/helpers";

const FormTextarea = ({name, form, wrapperClassName, ...rest}) => {
  return (
    <div className={classNames(styles['wrapper'], `${abEchoClass('textarea', sanitizeTextForClassName(name))}`, wrapperClassName)}>
      <Textarea
        name={name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        value={form.values[name]}
        {...rest}
      />
      {(form.touched[name] && form.errors[name]) &&
      <span>{form.errors[name]}</span>
      }
    </div>
  )
}

export default FormTextarea;
