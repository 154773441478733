import React from 'react';
import VendorModal from 'react-modal';

import classNames from 'classnames'

import {abEchoClass, sanitizeTextForClassName} from "../../helpers/helpers";

import {ReactComponent as CloseIcon} from 'assets/icons/close.svg';

import styles from './index.module.scss';

const Modal = ({title = '', onRequestClose = () => null, overlayClassName, wrapperClassName, hideCloseButton = false, className, children, onClick, ...rest}) => (
    <div onClick={onClick}>
      <VendorModal
          {...rest}
          overlayClassName={classNames(styles['overlay'], overlayClassName, abEchoClass('modal-overlay', sanitizeTextForClassName(title)))}
          className={classNames(styles['wrapper'], wrapperClassName, 'font-primary', abEchoClass('modal', sanitizeTextForClassName(title)))}
          onRequestClose={onRequestClose}
      >

        <header>
          <div className={classNames(styles.container, 'modal-headerContainer')}>
            {title}
            {!hideCloseButton && <CloseIcon onClick={onRequestClose}/>}
          </div>
        </header>

        <main className={classNames(className)}>{children}</main>

      </VendorModal>
    </div>
);

export default Modal;
